import { useState, useMemo, useRef } from 'react'
import '../css/PerformanceTable.css'
import './PerformanceMetaGADirectHeader.js'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge'
import { MultiStepLoader } from '../components/ui/multi-step-loader'
import { CSVLink } from 'react-csv'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faStar,
  faStarHalf,
  faArrowUp,
  faArrowDown,
  faHashtag,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'react-bootstrap'
import FavoritesTable from './PerformanceTableSubComponents/PerformanceFavoritesTable.js'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { AgGridReact } from 'ag-grid-react'

import { TypewriterEffect } from './ui/typewriter-effect.js'

function PerformanceTable ({
  startDate,
  endDate,
  selectedLeadType,
  selectedAdAccount,
  tableData,
  selectedLevel,
  campaignSearch,
  adsetSearch,
  adSearch,
  setSelectedLevel,
  loadingP,
  setLoadingP,
  advancedFilterSelections,
  selectedColumnFilters,
  advancedFilterModalShow,
  setSelectedRows,
  selectedRows,
  setSelectedASIDCount,
  setSelectedAIDCount,
  setSelectedCAIDCount,
  statusFilters,
  activeAds,
  contentTypeFilters,
  consolidationType,
  checkAuthenticationInResponse,
  APIEndpointDomain,
  tableDataAPICalling,
  Data,
  setData,
  compareRangeRequirement,
  setCompareRangeRequirement,
  comparisonTableData,
  Reminders,
  setReminders,
  PrimaryRangeProcessed,
  CompareRangeProcessed,
  setPrimaryRangeProcessed,
  setCompareRangeProcessed,
  activeAdsets,
  activeCampaigns,
  favoritesData,
  setFavoritesData,
  favoriteCAIDs,
  setFavoriteCAIDs,
  favoriteASIDs,
  setFavoriteASIDs,
  favoriteAIDs,
  setFavoriteAIDs,
  selectedCustomReport,
  setSelectedCustomReport,
  campaignBudgets,
  adsetBudgets,
  setCampaignBudgets,
  setAdsetBudgets,
  averageStandards,
  showAvgComparisonIndicators,
  setShowAvgComparisonIndicators,
  setActiveAdsets,
  setActiveCampaigns,
  setActiveAds,
  setCampaignSearch,
  setAdsetSearch,
  setAdSearch,
  showAdTagModal,
  setShowAdTagModal,
  ClustersTableData,
  setClustersTableData,
  setClusterNames,
  adsTagged,
  setAdsTagged,
  clusterNames,
  DataIsLeadType,
  exportCSVData,
  setExportCSVData,
  isFirstLoad,
  setIsFirstLoad,
  aggregateAds,
  setAggregateAds,

  currentRevenue,
  previousRevenue,
  currentSales,
  previousSales,
  currentCPA,
  previousCPA,
  currentCPL,
  previousCPL,
  topPerformers,
  recentSales,
  AttributedClients,
  AttributedRevenue,
  setAttributedClients,
  setAttributedRevenue,
  showTrends,
  setShowTrends,
  trendsData,
  setTotalsData,

  showSpendTrends,
  setShowSpendTrends
}) {
  var loadingStates = [
    {
      text: 'Connecting to Server'
    },
    {
      text: 'Fetching Blog Campaigns'
    },
    {
      text: 'Analyzing User Sessions'
    },
    {
      text: 'Fetching User Appointments'
    },
    {
      text: 'Mapping Appointments To Ads'
    },

    {
      text: 'performing calculations'
    },

    {
      text: 'Finalizing data'
    }
  ]

  var [tagsToShowOnAdTagModal, setTagsToShowOnAdTagModal] = useState('')

  var percentageMetrics = [
    'acquisition_loss_rate',
    'acquisition_validation_rate',
    'acquisitions_pending_validation_p',
    'good_percentage',
    'pending_p',
    'pressure_to_acquisition',
    'projected_roi',
    'quality',
    'roi',
    'uhl_to_acquisition',
    'roi_during',
    'h_good_percentage',
    'lead_to_h_good_percentage',
    'lead_to_h_ultra_percentage',
    'ctr',
    'hook_rate',
    'hold_rate',
    'view_to_click',
    'click_to_lead',
    'fifteen_to_25p',
    'twenty_five_p_to_50p',
    'fifty_p_to_75p',
    'real_roi_during'
  ]

  var currencyMetrics = [
    'cpaa',
    'premium_ms_revenue',
    'avg_purchase',
    'budget',
    'cpa',
    'cpgl',
    'cpuhl_h',
    'cpl',
    'cpql',
    'projected_cpa',
    'revenue',
    'total_revenue',
    'upfront_payments',
    'cpaa_during',
    'premium_ms_revenue_during',
    'admit_up_rev_dur',
    'cpa_during',
    'revenue_during',
    'total_revenue_during',
    'upfront_payments_during',
    'cpgl_historic',
    'h_cpa',
    'spend',
    'cpm',
    'cost_per_thruplay',
    'cpc',
    'real_revenue_during',
    'cpra_during'
  ]

  var [showChangeAdStatusModal, setShowAdStatusModal] = useState(false)
  var [clickedIDforStatusUpdate, setClickedIDforStatusUpdate] = useState(0)
  var [clickedNameforStatusUpdate, setClickedNameforStatusUpdate] = useState(0)

  var [showIndividualAdTagAssignModal, setShowIndividualAdTagAssignModal] =
    useState(false)
  var [showAdTagAssignModalFor, setShowAdTagAssignModalFor] = useState({})

  function renderActiveAdNamesTooltip (props) {
    return (
      <Tooltip id='button-tooltip' style={{ background: 'white' }}>
        {tableData
          .filter(
            thisRow =>
              (thisRow.campaign_id === props || thisRow.adset_id === props) &&
              (activeAds.includes(thisRow.ad_id) ||
                activeAdsets.includes(thisRow.adset_id))
          )
          .map(row => (
            <ListGroup.Item>{row.ad_name}</ListGroup.Item>
          ))}
      </Tooltip>
    )
  }

  function renderButtonTooltip (message) {
    return (
      <Tooltip id='button-tooltip' style={{ background: 'white' }}>
        {message}
      </Tooltip>
    )
  }

  function renderActiveAdsetNamesTooltip (props) {
    var adset_ids = activeAdsets.filter(adset_id =>
      tableData
        .filter(thisRow => thisRow.campaign_id === props)
        .some(thisRow => thisRow.adset_id === adset_id)
    )

    function uniqueArray (arr) {
      const unique = []

      for (const item of arr) {
        if (!unique.includes(item)) {
          unique.push(item)
        }
      }

      return unique
    }

    var adset_names = uniqueArray(
      tableData
        .filter(row => adset_ids.includes(row.adset_id))
        .map(row => row['adset_name'])
    )

    return (
      <Tooltip id='button-tooltip' style={{ background: 'white' }}>
        {adset_names.map(name => (
          <ListGroup.Item>{name}</ListGroup.Item>
        ))}
      </Tooltip>
    )
  }

  var [showUpdateBudgetModal, setShowUpdateBudgetModal] = useState(false)
  var [clickedIDforBudgetUpdate, setClickedIDforBudgetUpdate] = useState(0)

  var [
    currentBudgetValueForUpdateBudgetModal,
    setCurrentBudgetValueForUpdateBudgetModal
  ] = useState(0)

  function handleUpdateBudgetClick (id, budget) {
    setClickedIDforBudgetUpdate(id)
    setShowUpdateBudgetModal(true)
    setCurrentBudgetValueForUpdateBudgetModal(budget)
  }

  var [DeletionData, setDeletionData] = useState()
  var [previewHTML, setPreviewHTML] = useState(null)
  var [previewPlacement, setPreviewPlacement] = useState(
    'DESKTOP_FEED_STANDARD'
  )
  var [adBeingPreviewed, setAdBeingPreviewed] = useState(null)

  var [showAdPreviewModal, setShowAdPreviewModal] = useState(false)
  var [tableClickData, setTableClickData] = useState()
  var [sortType, setSortType] = useState()
  var [sortHeader, setSortHeader] = useState('Spend')
  var [headerTotal, setHeaderTotal] = useState({})

  var [showReminderModal, setShowReminderModal] = useState(false)
  var [reminderAdID, setReminderAdID] = useState(0)

  var [ComparisonHeaderTotal, setComparisonHeaderTotal] = useState({})
  var [sortHeaderDependency, setSortHeaderDependency] = useState(false)
  var [showDeletionModal, setShowDeletionModal] = useState(false)
  var [clickedDeletionIDS, setClickedDeletionIDS] = useState({
    campaign_id: null,
    adset_id: null,
    ad_id: null
  })
  var [hideTableNameConsolidation, setHideTableNameConsolidation] =
    useState(false)

  var [showTableDataClickModal, setShowTableDataClickModal] = useState(false)
  var [tableDataClickAPIURL, setTableDataClickAPIURL] = useState('')

  var [clickedTableDataIDS, setClickedTableDataIDS] = useState({
    campaign_id: null,
    adset_id: null,
    ad_id: null
  })

  var [showReminderMessage, setShowReminderMessage] = useState(false)

  useEffect(() => {
    if (consolidationType === 'name' && selectedLevel !== 'Ad') {
      setHideTableNameConsolidation(false)
    } else {
      setHideTableNameConsolidation(true)
    }
  }, [consolidationType, selectedLevel])

  function handleDeletionDataClick (caid, asid, aid, column_name) {
    showDeletionModal === false
      ? setShowDeletionModal(true)
      : setShowDeletionModal(false)

    setClickedDeletionIDS({
      campaign_id: caid,
      adset_id: asid,
      ad_id: aid,
      column_name: column_name
    })

    setDeletionData(null)

    var accounts = encodeURIComponent(
      JSON.stringify(
        selectedAdAccount.map(account_name =>
          account_name.toLowerCase().replace('-', '_')
        )
      )
    )

    axios
      .get(APIEndpointDomain + '/deletion', {
        params: {
          start_date: startDate,
          end_date: endDate,
          lead_type: selectedLeadType.toLowerCase().replace('-', '_'),
          ad_account: accounts,
          campaign_id: caid,
          adset_id: asid,
          ad_id: aid,
          column_name: column_name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        const responseData = response.data['deletion_data']
        const filteredReponse = responseData.filter(row => {
          return row.count > 0
        })
        setDeletionData(filteredReponse)
      })
      .catch(error => {
        setDeletionData([{ delete_reason: null }])
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  var [trendlinesTD, setTrendlinesTD] = useState([])
  var [profileInformationTD, setProfileInformationTD] = useState([])
  var [currentStagesTD, setCurrentStagesTD] = useState([])

  function handleTableDataClick (caid, asid, aid, column_name) {
    setTrendlinesTD([])
    setProfileInformationTD({})
    setCurrentStagesTD([])
    showTableDataClickModal === false
      ? setShowTableDataClickModal(true)
      : setShowTableDataClickModal(false)

    setClickedTableDataIDS({
      campaign_id: caid,
      adset_id: asid,
      ad_id: aid,
      column_name: column_name
    })

    setTableClickData(null)

    var tableDataClickAPIURL = 'performance/detailed-information'
    setTableDataClickAPIURL(tableDataClickAPIURL)

    var accounts = encodeURIComponent(
      JSON.stringify(
        selectedAdAccount.map(account_name =>
          account_name.toLowerCase().replace('-', '_')
        )
      )
    )

    axios
      .get(APIEndpointDomain + '/' + tableDataClickAPIURL, {
        params: {
          start_date: startDate,
          end_date: endDate,
          lead_type: selectedLeadType.toLowerCase().replace('-', '_'),
          ad_account: accounts,
          campaign_id: caid,
          adset_id: asid,
          ad_id: aid,
          column_name: column_name
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        setTrendlinesTD(response.data['trendlines'])
        setProfileInformationTD(response.data['profile_information'])
        setCurrentStagesTD(response.data['stages'])
      })
      .catch(error => {
        setTableClickData([{ result: null }])
        checkAuthenticationInResponse(Number(error.response.status))
        alert('Failed to load, please close the popup and try again!')
      })
  }

  function isNumber (n) {
    return Number(n) === n
  }

  function calculateNewColumns (groupedData) {
    groupedData.map(data => {
      var quality =
        data['leads'] !== 0
          ? (100 * data['qualified']) / (data['leads'] - data['pending'])
          : 0

      var cpl = data['leads'] !== 0 ? data['spend'] / data['leads'] : 0

      var cpql = data['qualified'] !== 0 ? data['spend'] / data['qualified'] : 0

      var cpgl =
        data['good_leads'] !== 0 ? data['spend'] / data['good_leads'] : 0

      var cpuhl_h = data['h_ultra'] !== 0 ? data['spend'] / data['h_ultra'] : 0

      var cpa =
        data['acquisitions'] !== 0 ? data['spend'] / data['acquisitions'] : 0

      var h_cpa =
        data['h_acquisitions'] !== 0
          ? data['spend'] / data['h_acquisitions']
          : 0
      var cpaa =
        data['actual_acquisitions'] !== 0
          ? data['spend'] / data['actual_acquisitions']
          : 0

      var days_active = getMaxDaysActive(data)

      var acquisitions_lost = data['h_acquisitions'] - data['acquisitions']
      var acquisition_loss_rate =
        data['h_acquisitions'] > 0
          ? (100 * (data['h_acquisitions'] - data['acquisitions'])) /
            data['h_acquisitions']
          : 0

      var acquisition_validation_rate =
        data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']) >
        0
          ? (100 * data['actual_acquisitions']) /
            (data['actual_acquisitions'] +
              (data['h_acquisitions'] - data['acquisitions']))
          : 0

      var acquisitions_pending_validation =
        data['h_acquisitions'] -
        (data['actual_acquisitions'] +
          (data['h_acquisitions'] - data['acquisitions']))

      var acquisitions_pending_validation_p =
        data['h_acquisitions'] > 0
          ? (100 *
              (data['h_acquisitions'] -
                (data['actual_acquisitions'] +
                  (data['h_acquisitions'] - data['acquisitions'])))) /
            data['h_acquisitions']
          : 0

      var cpaa_during =
        data['actually_acquired_during'] !== 0
          ? data['spend'] / data['actually_acquired_during']
          : 0

      var cpra_during =
        data['real_acquisitions_during'] !== 0
          ? data['spend'] / data['real_acquisitions_during']
          : 0

      var ttl_revenue = data['revenue'] + data['admit_up_rev']

      var roi =
        data['spend'] !== 0
          ? (data['revenue'] + data['admit_up_rev']) / data['spend']
          : 0

      var real_roi_during =
        data['spend'] !== 0
          ? (100 * data['real_revenue_during']) / data['spend']
          : 0

      var roi_during =
        data['spend'] !== 0 ? data['revenue_during'] / data['spend'] : 0

      var pending_p =
        data['leads'] !== 0 ? (100 * data['pending']) / data['leads'] : 0

      var cpgl_historic =
        data['h_good'] !== 0 ? data['spend'] / data['h_good'] : 0

      var cpa_during =
        data['acquired_during'] !== 0
          ? data['spend'] / data['acquired_during']
          : 0

      var ttl_revenue_du = data['admit_up_rev_dur'] + data['revenue_during']

      var good_percentage =
        data['qualified'] !== 0
          ? (100 * data['good_leads']) / data['qualified']
          : 0

      var h_good_percentage =
        data['qualified'] !== 0 ? (100 * data['h_good']) / data['qualified'] : 0

      var lead_to_h_good_percentage =
        data['leads'] !== 0 ? (100 * data['h_good']) / data['leads'] : 0

      var lead_to_h_ultra_percentage =
        data['leads'] !== 0 ? (100 * data['h_ultra']) / data['leads'] : 0

      var uhl_to_acquisition =
        data['h_ultra'] !== 0
          ? (100 * data['acquisitions']) / (data['h_ultra'] - data['ultra_hot'])
          : 0

      var pressure_to_acquisition =
        data['h_pressure'] !== 0
          ? (100 * data['acquisitions']) /
            (data['h_pressure'] - data['pressure_sell'])
          : 0

      var projected_acquisitions =
        data['acquisitions'] +
        (data['ultra_hot'] / 100) * uhl_to_acquisition +
        (data['pressure_sell'] / 100) * pressure_to_acquisition

      var projected_cpa =
        data['acquisitions'] +
          (data['ultra_hot'] / 100) * uhl_to_acquisition +
          (data['pressure_sell'] / 100) * pressure_to_acquisition !==
        0
          ? data['spend'] /
            (data['acquisitions'] +
              (data['ultra_hot'] / 100) * uhl_to_acquisition +
              (data['pressure_sell'] / 100) * pressure_to_acquisition)
          : 0

      var avg_purchase =
        data['acquisitions'] !== 0 ? data['revenue'] / data['acquisitions'] : 0

      var projected_roi =
        data['spend'] !== 0
          ? (100 *
              (data['revenue'] +
                data['admit_up_rev'] +
                (avg_purchase * data['ultra_hot'] * uhl_to_acquisition) / 100 +
                (avg_purchase *
                  data['pressure_sell'] *
                  pressure_to_acquisition) /
                  100)) /
            data['spend']
          : 0

      var avg_conversion_period =
        data['acquired_during'] !== 0 &&
        ['premium', 'gmat', 'jobs'].includes(DataIsLeadType.toLowerCase())
          ? data['conversion_period'] / data['acquired_during']
          : data['acquisitions'] !== 0 && DataIsLeadType.toLowerCase() == 'cpa'
          ? data['conversion_period'] / data['acquisitions']
          : 0

      var avg_validation_period =
        data['actual_acquisitions'] !== 0
          ? data['validation_period'] / data['actual_acquisitions']
          : 0

      var avg_complete_cycle_period =
        data['actual_acquisitions'] !== 0
          ? data['creation_to_complete_validation_period'] /
            data['actual_acquisitions']
          : 0

      //

      var cpm =
        data['impressions'] !== 0
          ? (1000 * data['spend']) / data['impressions']
          : 0

      var cpc =
        data['link_clicks'] !== 0 ? data['spend'] / data['link_clicks'] : 0

      var ctr =
        data['impressions'] !== 0
          ? (100 * data['link_clicks']) / data['impressions']
          : 0

      var ctr = Math.round((ctr + Number.EPSILON) * 100) / 100

      var view_to_click =
        data['thruplays'] !== 0
          ? (100 * data['link_clicks']) / data['thruplays']
          : 0

      var cost_per_thruplay =
        data['thruplays'] !== 0 ? data['spend'] / data['thruplays'] : 0

      var cpc =
        data['link_clicks'] !== 0 ? data['spend'] / data['link_clicks'] : 0

      var hook_rate = data['impressions']
        ? (100 * data['three_sec_plays']) / data['impressions']
        : 0

      var hold_rate = data['three_sec_plays']
        ? (100 * data['thruplays']) / data['three_sec_plays']
        : 0

      var click_to_lead =
        data['link_clicks'] !== 0
          ? (100 * data['leads']) / data['link_clicks']
          : 0

      var click_to_lead =
        Math.round((click_to_lead + Number.EPSILON) * 100) / 100

      var fifteen_to_25p =
        data['thruplays'] !== 0
          ? (100 * data['twenty_five_p_plays']) / data['thruplays']
          : 0

      var twenty_five_p_to_50p =
        data['twenty_five_p_plays'] !== 0
          ? (100 * data['fifty_p_plays']) / data['twenty_five_p_plays']
          : 0

      var fifty_p_to_75p =
        data['fifty_p_plays'] !== 0
          ? (100 * data['seventy_five_p_plays']) / data['fifty_p_plays']
          : 0

      var held_per_1k_impressions =
        data['impressions'] !== 0 && hook_rate > 0 && hold_rate > 0
          ? (((1000 * hook_rate) / 100) * hold_rate) / 100
          : 0

      data['fifteen_to_25p'] = isNumber(fifteen_to_25p)
        ? Math.round(fifteen_to_25p)
        : fifteen_to_25p
      data['twenty_five_p_to_50p'] = isNumber(twenty_five_p_to_50p)
        ? Math.round(twenty_five_p_to_50p)
        : fifteen_to_25p
      data['fifty_p_to_75p'] = isNumber(fifty_p_to_75p)
        ? Math.round(fifty_p_to_75p)
        : fifteen_to_25p
      data['held_per_1k_impressions'] = isNumber(held_per_1k_impressions)
        ? Math.round(held_per_1k_impressions)
        : fifteen_to_25p

      data['cost_per_thruplay'] = isNumber(cost_per_thruplay)
        ? Math.round(cost_per_thruplay)
        : cost_per_thruplay
      data['cpc'] = isNumber(cpc) ? Math.round(cpc) : cpc
      data['hook_rate'] = isNumber(hook_rate)
        ? Math.round(hook_rate)
        : hook_rate
      data['hold_rate'] = isNumber(hold_rate)
        ? Math.round(hold_rate)
        : hold_rate

      data['real_roi_during'] = isNumber(real_roi_during)
        ? Math.round(real_roi_during)
        : real_roi_during

      data['acquisitions_pending_validation'] = acquisitions_pending_validation
      data['acquisitions_pending_validation_p'] =
        acquisitions_pending_validation_p
      data['cpm'] = isNumber(cpm) ? Math.round(cpm) : cpm
      data['cpc'] = isNumber(cpc) ? Math.round(cpc) : cpc
      data['hook_rate'] = isNumber(hook_rate)
        ? Math.round(hook_rate)
        : hook_rate
      data['ctr'] = isNumber(ctr) ? ctr : ctr

      data['view_to_click'] = isNumber(view_to_click)
        ? Math.round(view_to_click)
        : view_to_click
      data['click_to_lead'] = isNumber(click_to_lead)
        ? click_to_lead
        : click_to_lead

      data['acquisitions_lost'] = acquisitions_lost
      data['acquisition_loss_rate'] = acquisition_loss_rate
      data['avg_complete_cycle_period'] = avg_complete_cycle_period

      data['quality'] = isNumber(quality) ? Math.round(quality) : 0
      data['cpl'] = isNumber(cpl) ? Math.round(cpl) : cpl
      data['cpql'] = isNumber(cpql) ? Math.round(cpql) : cpql
      data['cpgl'] = isNumber(cpgl) ? Math.round(cpgl) : cpgl
      data['cpuhl_h'] = isNumber(cpuhl_h) ? Math.round(cpuhl_h) : cpuhl_h
      data['cpa'] = isNumber(cpa) ? Math.round(cpa) : cpa
      data['h_cpa'] = isNumber(h_cpa) ? Math.round(h_cpa) : h_cpa
      data['cpaa'] = isNumber(cpaa) ? Math.round(cpaa) : cpaa
      data['cpaa_during'] = isNumber(cpaa_during)
        ? Math.round(cpaa_during)
        : cpaa_during

      data['cpra_during'] = isNumber(cpra_during)
        ? Math.round(cpra_during)
        : cpra_during

      data['lead_to_h_good_percentage'] = isNumber(lead_to_h_good_percentage)
        ? Math.round(lead_to_h_good_percentage)
        : lead_to_h_good_percentage

      data['lead_to_h_ultra_percentage'] = isNumber(lead_to_h_ultra_percentage)
        ? Math.round(lead_to_h_ultra_percentage)
        : lead_to_h_ultra_percentage

      data['total_revenue'] = ttl_revenue
      data['roi'] = isNumber(roi) ? Math.round(100 * roi) : roi
      data['roi_during'] = isNumber(roi_during)
        ? Math.round(100 * roi_during)
        : roi_during
      data['pending_p'] = isNumber(pending_p)
        ? Math.round(pending_p)
        : pending_p
      data['cpgl_historic'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic

      data['h_cpgl'] = isNumber(cpgl_historic)
        ? Math.round(cpgl_historic)
        : cpgl_historic
      data['cpa_during'] = isNumber(cpa_during)
        ? Math.round(cpa_during)
        : cpa_during
      data['total_revenue_during'] = ttl_revenue_du
      data['good_percentage'] = good_percentage
      data['h_good_percentage'] = h_good_percentage
      data['pressure_to_acquisition'] = pressure_to_acquisition
      data['uhl_to_acquisition'] = uhl_to_acquisition

      data['avg_validation_period'] = avg_validation_period

      data['projected_acquisitions'] = projected_acquisitions
      data['projected_cpa'] = projected_cpa
      data['projected_roi'] = projected_roi
      data['avg_purchase'] = avg_purchase
      data['avg_conversion_period'] = avg_conversion_period
      data['acquisition_validation_rate'] = acquisition_validation_rate
      data['upfront_payments'] =
        isNumber(data['revenue']) && selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue'] - 20000 * data['acquisitions']
          : isNumber(data['revenue']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue']
          : 0

      data['upfront_payments_during'] =
        isNumber(data['revenue_during']) &&
        selectedLeadType.toLowerCase() === 'cpa'
          ? data['revenue_during'] - 20000 * data['acquired_during']
          : isNumber(data['revenue_during']) &&
            selectedLeadType.toLowerCase() === 'premium'
          ? data['revenue_during']
          : 0
    })

    return groupedData
  }

  var averageStandardMetrics = Object.keys(averageStandards)

  function sumEachColumn (data) {
    var useData =
      selectedLevel === 'Campaign' && selectedCAIDS.length > 0
        ? data.filter(row => {
            return selectedCAIDS.includes(row.campaign_id)
          })
        : selectedLevel === 'Adset' && selectedASIDS.length > 0
        ? data.filter(row => {
            return selectedASIDS.includes(row.adset_id)
          })
        : selectedLevel === 'Ad' && selectedAIDS.length > 0
        ? data.filter(row => {
            return selectedAIDS.includes(row.ad_id)
          })
        : data

    var calculatedHeader = {}

    useData.forEach(obj => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          calculatedHeader[key] = (calculatedHeader[key] || 0) + obj[key]
        }
      }
    })

    return calculatedHeader
  }

  function calculateHeaderTotals (calculatedHeader) {
    calculatedHeader['roi'] =
      (100 * (calculatedHeader['revenue'] + calculatedHeader['admit_up_rev'])) /
      calculatedHeader['spend']

    calculatedHeader['roi_during'] =
      (100 * calculatedHeader['revenue_during']) / calculatedHeader['spend']

    calculatedHeader['quality'] =
      (100 * calculatedHeader['qualified']) /
      (calculatedHeader['leads'] - calculatedHeader['pending'])

    calculatedHeader['avg_complete_cycle_period'] =
      calculatedHeader['actual_acquisitions'] !== 0
        ? calculatedHeader['creation_to_complete_validation_period'] /
          calculatedHeader['actual_acquisitions']
        : 0

    calculatedHeader['real_roi_during'] = calculatedHeader['spend']
      ? (100 * calculatedHeader['real_revenue_during']) /
        calculatedHeader['spend']
      : 0

    calculatedHeader['cpgl_historic'] =
      calculatedHeader['h_good'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['h_good']
        : 0

    calculatedHeader['h_cpgl'] =
      calculatedHeader['h_good'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['h_good']
        : 0

    calculatedHeader['cpuhl_h'] =
      calculatedHeader['h_ultra'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['h_ultra']
        : 0
    calculatedHeader['cpra_during'] =
      calculatedHeader['real_acquisitions_during'] !== 0
        ? calculatedHeader['spend'] /
          calculatedHeader['real_acquisitions_during']
        : 0
    calculatedHeader['cpl'] =
      calculatedHeader['spend'] / calculatedHeader['leads']

    calculatedHeader['cpql'] =
      calculatedHeader['spend'] / calculatedHeader['qualified']

    calculatedHeader['cpgl'] =
      calculatedHeader['spend'] / calculatedHeader['good_leads']

    calculatedHeader['cpa'] =
      calculatedHeader['spend'] / calculatedHeader['acquisitions']

    calculatedHeader['ttl_revenue'] =
      calculatedHeader['revenue'] + calculatedHeader['admit_up_rev']

    calculatedHeader['pending_p'] =
      (100 * calculatedHeader['pending']) / calculatedHeader['leads']

    calculatedHeader['cpgl_hist'] =
      calculatedHeader['spend'] / calculatedHeader['h_good']

    calculatedHeader['lead_to_h_good_percentage'] =
      calculatedHeader['leads'] !== 0
        ? (100 * calculatedHeader['h_good']) / calculatedHeader['leads']
        : 0
    calculatedHeader['lead_to_h_ultra_percentage'] =
      calculatedHeader['leads'] !== 0
        ? (100 * calculatedHeader['h_ultra']) / calculatedHeader['leads']
        : 0

    calculatedHeader['cpa_during'] =
      calculatedHeader['spend'] / calculatedHeader['acquired_during']

    calculatedHeader['good_percentage'] =
      (100 * calculatedHeader['good_leads']) / calculatedHeader['qualified']

    calculatedHeader['h_good_percentage'] =
      (100 * calculatedHeader['h_good']) / calculatedHeader['qualified']

    calculatedHeader['ttl_revenue_du'] =
      calculatedHeader['admit_up_rev_dur'] + calculatedHeader['revenue_during']

    calculatedHeader['pressure_to_acquisition'] =
      (100 * calculatedHeader['acquisitions']) /
      (calculatedHeader['h_pressure'] - calculatedHeader['pressure_sell'])

    calculatedHeader['uhl_to_acquisition'] =
      (100 * calculatedHeader['acquisitions']) /
      (calculatedHeader['h_ultra'] - calculatedHeader['ultra_hot'])

    calculatedHeader['projected_cpa'] =
      calculatedHeader['spend'] / calculatedHeader['projected_acquisitions']

    calculatedHeader['avg_purchase'] =
      calculatedHeader['revenue'] / calculatedHeader['acquisitions']

    calculatedHeader['projected_roi'] =
      (100 *
        (calculatedHeader['revenue'] +
          calculatedHeader['admit_up_rev'] +
          ((calculatedHeader['revenue'] / calculatedHeader['acquisitions']) *
            calculatedHeader['ultra_hot'] *
            ((100 * calculatedHeader['acquisitions']) /
              (calculatedHeader['h_ultra'] - calculatedHeader['ultra_hot']))) /
            100 +
          ((calculatedHeader['revenue'] / calculatedHeader['acquisitions']) *
            calculatedHeader['pressure_sell'] *
            ((100 * calculatedHeader['acquisitions']) /
              (calculatedHeader['h_pressure'] -
                calculatedHeader['pressure_sell']))) /
            100)) /
      calculatedHeader['spend']

    calculatedHeader['h_cpa'] =
      calculatedHeader['h_acquisitions'] > 0
        ? calculatedHeader['spend'] / calculatedHeader['h_acquisitions']
        : 0
    calculatedHeader['cpaa'] =
      calculatedHeader['actual_acquisitions'] > 0
        ? calculatedHeader['spend'] / calculatedHeader['actual_acquisitions']
        : 0
    calculatedHeader['cpaa_during'] =
      calculatedHeader['actually_acquired_during'] > 0
        ? calculatedHeader['spend'] /
          calculatedHeader['actually_acquired_during']
        : 0
    calculatedHeader['acquisition_loss_rate'] =
      calculatedHeader['h_acquisitions'] !== 0
        ? (100 * calculatedHeader['acquisitions_lost']) /
          calculatedHeader['h_acquisitions']
        : 0

    calculatedHeader['acquisition_validation_rate'] =
      calculatedHeader['actual_acquisitions'] +
        calculatedHeader['acquisitions_lost'] !==
      0
        ? (100 * calculatedHeader['actual_acquisitions']) /
          (calculatedHeader['actual_acquisitions'] +
            calculatedHeader['acquisitions_lost'])
        : 0

    calculatedHeader['campaign_id'] = ''
    calculatedHeader['adset_id'] = ''
    calculatedHeader['ad_id'] = ''

    calculatedHeader['days_active'] = ''
    calculatedHeader['avg_conversion_period'] =
      calculatedHeader['conversion_period'] / calculatedHeader['acquisitions']
    calculatedHeader['avg_validation_period'] =
      calculatedHeader['validation_period'] /
      calculatedHeader['actual_acquisitions']

    calculatedHeader['acquisitions_pending_validation'] =
      calculatedHeader['h_acquisitions'] -
      (calculatedHeader['actual_acquisitions'] +
        (calculatedHeader['h_acquisitions'] - calculatedHeader['acquisitions']))

    calculatedHeader['acquisitions_pending_validation_p'] =
      calculatedHeader['h_acquisitions'] > 0
        ? (100 *
            (calculatedHeader['h_acquisitions'] -
              (calculatedHeader['actual_acquisitions'] +
                (calculatedHeader['h_acquisitions'] -
                  calculatedHeader['acquisitions'])))) /
          calculatedHeader['h_acquisitions']
        : 0

    calculatedHeader['cpm'] =
      calculatedHeader['impressions'] !== 0
        ? (1000 * calculatedHeader['spend']) / calculatedHeader['impressions']
        : 0

    calculatedHeader['cost_per_thruplay'] =
      calculatedHeader['thruplays'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['thruplays']
        : 0

    calculatedHeader['cpc'] =
      calculatedHeader['link_clicks'] !== 0
        ? calculatedHeader['spend'] / calculatedHeader['link_clicks']
        : 0

    calculatedHeader['hook_rate'] = calculatedHeader['impressions']
      ? (100 * calculatedHeader['three_sec_plays']) /
        calculatedHeader['impressions']
      : 0

    calculatedHeader['hold_rate'] = calculatedHeader['three_sec_plays']
      ? (100 * calculatedHeader['thruplays']) /
        calculatedHeader['three_sec_plays']
      : 0

    calculatedHeader['view_to_click'] =
      calculatedHeader['thruplays'] !== 0
        ? (100 * calculatedHeader['link_clicks']) /
          calculatedHeader['thruplays']
        : 0

    calculatedHeader['ctr'] =
      calculatedHeader['impressions'] > 0
        ? Math.round(
            ((100 * calculatedHeader['link_clicks']) /
              calculatedHeader['impressions'] +
              Number.EPSILON) *
              100
          ) / 100
        : 0

    calculatedHeader['click_to_lead'] =
      calculatedHeader['link_clicks'] !== 0
        ? Math.round(
            ((100 * calculatedHeader['leads']) /
              calculatedHeader['link_clicks'] +
              Number.EPSILON) *
              100
          ) / 100
        : 0

    calculatedHeader['fifteen_to_25p'] =
      calculatedHeader['thruplays'] !== 0
        ? (100 * calculatedHeader['twenty_five_p_plays']) /
          calculatedHeader['thruplays']
        : 0

    calculatedHeader['twenty_five_p_to_50p'] =
      calculatedHeader['twenty_five_p_plays'] !== 0
        ? (100 * calculatedHeader['fifty_p_plays']) /
          calculatedHeader['twenty_five_p_plays']
        : 0

    calculatedHeader['fifty_p_to_75p'] =
      calculatedHeader['fifty_p_plays'] !== 0
        ? (100 * calculatedHeader['seventy_five_p_plays']) /
          calculatedHeader['fifty_p_plays']
        : 0

    calculatedHeader['held_per_1k_impressions'] =
      calculatedHeader['impressions'] !== 0 &&
      calculatedHeader['hook_rate'] > 0 &&
      calculatedHeader['hold_rate'] > 0
        ? (((1000 * calculatedHeader['hook_rate']) / 100) *
            calculatedHeader['hold_rate']) /
          100
        : 0

    return calculatedHeader
  }

  function groupByAdName (array) {
    const result = {}

    array.forEach(obj => {
      const { ad_name, ...rest } = obj

      if (!result[ad_name]) {
        result[ad_name] = { ad_name, ...rest }
      } else {
        for (let key in rest) {
          if (result[ad_name][key]) {
            result[ad_name][key] += rest[key]
          } else {
            result[ad_name][key] = rest[key]
          }
        }
      }
    })

    return Object.values(result)
  }

  function groupByLevel (data, level) {
    if (data && data.length >= 1 && level) {
      var name_referrer =
        level === 'Campaign'
          ? 'campaign_name'
          : level === 'Adset'
          ? 'adset_name'
          : level === 'Ad'
          ? 'ad_name'
          : ''
      var id_referrer =
        level === 'Campaign'
          ? 'campaign_id'
          : level === 'Adset'
          ? 'adset_id'
          : level === 'Ad'
          ? 'ad_id'
          : ''
      var removableColumns =
        consolidationType === 'id' && selectedLevel === 'Ad'
          ? [
              // "adset_name",
              'adset_id',
              'ad_name',
              'ad_id',
              // "campaign_name",
              'campaign_id'
            ]
          : consolidationType === 'id' && selectedLevel === 'Adset'
          ? [
              'adset_name',
              'adset_id',
              'ad_name',
              'ad_id',
              // "campaign_name",
              'campaign_id'
            ]
          : consolidationType === 'id'
          ? [
              'adset_name',
              'adset_id',
              'ad_name',
              'ad_id',
              'campaign_name',
              'campaign_id'
            ]
          : ['campaign_name', 'adset_name', 'ad_name']

      var identifiers = []
      var groupedData = []
      var all_keys = Object.keys(data[0]).filter(
        key => !removableColumns.includes(key)
      )

      data.map(row => {
        var name = row[name_referrer]

        var id = consolidationType === 'id' ? row[id_referrer] : name + 0
        var name_id_combination = name + id

        if (!identifiers.includes(name_id_combination)) {
          identifiers.push(name_id_combination)

          var updatedRow = {}

          all_keys.map(key => {
            if (key.includes('_export') && row[key] == 0) {
              updatedRow[key] = []
            } else {
              updatedRow[key] = row[key]
            }
          })

          updatedRow[name_referrer] = name
          updatedRow[id_referrer] = id

          groupedData.push(updatedRow)
        } else if (identifiers.includes(name_id_combination)) {
          var existingObject = groupedData.filter(
            groupedObject =>
              groupedObject[name_referrer] == name &&
              groupedObject[id_referrer] == id
          )[0]

          all_keys.map(key => {
            existingObject[key] =
              key === 'campaign_name' ||
              key === 'adset_name' ||
              key === 'ad_name' ||
              key === 'campaign_id' ||
              key === 'adset_id' ||
              key === 'ad_id' ||
              key === 'type' ||
              key === 'status'
                ? existingObject[key]
                : key.includes('_export') && row[key] == 0
                ? [...existingObject[key]]
                : key.includes('_export') && row[key] != 0
                ? [...existingObject[key], ...row[key]]
                : existingObject[key] + row[key]
            existingObject[name_referrer] = name
            existingObject[id_referrer] = id
          })
        }
      })

      groupedData = groupedData.map(row => {
        all_keys.forEach(key => {
          if (row[key] !== undefined && !key.includes('_export')) {
            row[key] = Math.round(row[key])
          }
        })
        return row
      })

      return groupedData
    } else {
      return tableData
    }
  }

  function selectingRows (selectedRow) {
    var newSelectedRows = selectedRows.some(row => {
      return (
        row.campaignID === selectedRow.campaignID &&
        row.adsetID === selectedRow.adsetID &&
        row.adID === selectedRow.adID
      )
    })
      ? selectedRows.filter(row => {
          return selectedLevel === 'Campaign'
            ? row.campaignID !== selectedRow.campaignID
            : selectedLevel === 'Adset'
            ? row.adsetID !== selectedRow.adsetID
            : selectedLevel === 'Ad'
            ? row.adID !== selectedRow.adID
            : null
        })
      : [...selectedRows, selectedRow]
    setSelectedRows(newSelectedRows)
  }

  function selectUnselectAll () {
    var rows = []

    if (selectedRows.length === 0 && selectedLevel === 'Campaign') {
      for (let i = 0; i < Data.length; i++) {
        rows.push({
          campaignID: Data[i]['campaign_id'],
          adsetID: null,
          adID: null
        })
      }
    } else if (selectedRows.length === 0 && selectedLevel === 'Adset') {
      for (let i = 0; i < Data.length; i++) {
        rows.push({
          campaignID: null,
          adsetID: Data[i]['adset_id'],
          adID: null
        })
      }
    } else if (selectedRows.length === 0 && selectedLevel === 'Ad') {
      for (let i = 0; i < Data.length; i++) {
        rows.push({
          campaignID: null,
          adsetID: null,
          adID: Data[i]['ad_id']
        })
      }
    }

    setSelectedRows(rows)
  }

  // function handleRowDataClick (clickedRow) {
  //   if (selectedLevel === 'Campaign') {
  //     setSelectedLevel('Adset')
  //   } else if (selectedLevel === 'Adset') {
  //     setSelectedLevel('Ad')
  //   }

  //   setSelectedRows([clickedRow])
  // }

  var selectedCAIDS =
    selectedRows.length > 0
      ? selectedRows.map(row => row.campaignID).filter(iRow => iRow !== null)
      : []

  var selectedASIDS =
    selectedRows.length > 0 && selectedCAIDS.length > 0
      ? selectedRows
          .map(row => row.adsetID)
          .filter(item => item !== null)
          .filter(
            iItem =>
              tableData.filter(
                data =>
                  data.adset_id === iItem &&
                  selectedCAIDS.includes(data.campaign_id)
              ).length > 0
          )
      : selectedRows.length > 0 && selectedCAIDS.length === 0
      ? selectedRows.map(row => row.adsetID).filter(item => item !== null)
      : []

  var selectedAIDS =
    selectedRows.length > 0 &&
    selectedCAIDS.length > 0 &&
    selectedASIDS.length == 0
      ? selectedRows
          .map(row => row.adID)
          .filter(item => item !== null)
          .filter(
            iItem =>
              tableData.filter(
                data =>
                  data.ad_id === iItem &&
                  selectedCAIDS.includes(data.campaign_id)
              ).length > 0
          )
      : selectedRows.length > 0 &&
        selectedCAIDS.length == 0 &&
        selectedASIDS.length > 0
      ? selectedRows
          .map(row => row.adID)
          .filter(item => item !== null)
          .filter(
            iItem =>
              tableData.filter(
                data =>
                  data.ad_id === iItem && selectedASIDS.includes(data.adset_id)
              ).length > 0
          )
      : selectedRows.length > 0 &&
        selectedCAIDS.length > 0 &&
        selectedASIDS.length > 0
      ? selectedRows
          .map(row => row.adID)
          .filter(item => item !== null)
          .filter(
            iItem =>
              tableData.filter(
                data =>
                  data.ad_id === iItem &&
                  selectedASIDS.includes(data.adset_id) &&
                  selectedCAIDS.includes(data.campaign_id)
              ).length > 0
          )
      : selectedRows.length > 0 &&
        selectedCAIDS.length === 0 &&
        selectedASIDS.length === 0
      ? selectedRows
          .map(row => row.adID)
          .filter(item => item !== null)
          .filter(
            iItem => tableData.filter(data => data.ad_id === iItem).length > 0
          )
      : []

  function StatusTypeFiltering (data) {
    var statusFiltered =
      statusFilters.active === true && statusFilters.paused === true
        ? data
        : statusFilters.active === true && statusFilters.paused === false
        ? data.filter(row => {
            return activeAds.includes(row['ad_id'])
          })
        : statusFilters.active === false && statusFilters.paused === true
        ? data.filter(row => {
            return !activeAds.includes(row['ad_id'])
          })
        : data

    var typeFiltered =
      contentTypeFilters.reel === true && contentTypeFilters.image === true
        ? statusFiltered
        : contentTypeFilters.reel === true && contentTypeFilters.image === false
        ? statusFiltered.filter(row => {
            return row.type == 'reel' && row.spend > 20
          })
        : contentTypeFilters.reel === false && contentTypeFilters.image === true
        ? statusFiltered.filter(row => {
            return row.type == 'image' && row.spend > 20
          })
        : statusFiltered

    return typeFiltered
  }

  const ReminderCAIDs = Reminders.map(reminder => reminder['campaign_id'])

  const ReminderASIDs = Reminders.map(reminder => reminder['adset_id'])

  const ReminderAIDs = Reminders.map(reminder => reminder['ad_id'])

  function addBudgetsToData (Data, selectedLevel, adsetBudgets) {
    if (!Array.isArray(Data) || !Array.isArray(adsetBudgets)) {
      throw new Error('Data and adsetBudgets must be arrays')
    }

    return Data.map(row => {
      let budget = 0

      if (selectedLevel === 'Ad') {
        budget = 0
      } else if (selectedLevel === 'Adset') {
        const adset = adsetBudgets.find(
          adset => String(adset.folder_id) === String(row.adset_id)
        )
        budget = adset ? adset.budget : 0
      } else if (selectedLevel === 'Campaign') {
        const adsetIDSForCampaign = Data.filter(
          r => r.campaign_id === row.campaign_id
        ).map(ro => ro.adset_id)

        const relatedAdsets = adsetBudgets.filter(adset =>
          adsetIDSForCampaign.includes(adset.folder_id)
        )

        const uniqueAdsetIds = [...new Set(relatedAdsets.map(r => r.folder_id))]

        uniqueAdsetIds.forEach(adsetId => {
          const adset = adsetBudgets.find(
            adset => String(adset.folder_id) === String(adsetId)
          )
          const adsetBudget = adset ? adset.budget : 0
          budget += adsetBudget
        })
      } else {
        budget = 0
      }

      return {
        ...row,
        budget
      }
    })
  }

  useEffect(() => {
    setPrimaryRangeProcessed(false)

    var statusFiltered = StatusTypeFiltering(tableData)

    var filteredData =
      selectedLevel === 'Adset' && selectedCAIDS.length >= 1
        ? statusFiltered.filter(data =>
            selectedCAIDS.some(
              id =>
                id === data['campaign_id'] &&
                data['campaign_name']
                  .toLowerCase()
                  .includes(campaignSearch.toLowerCase()) &&
                data['adset_name']
                  .toLowerCase()
                  .includes(adsetSearch.toLowerCase()) &&
                data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
            )
          )
        : selectedLevel === 'Ad' &&
          selectedCAIDS.length >= 1 &&
          selectedASIDS.length >= 1
        ? statusFiltered
            .filter(data =>
              selectedCAIDS.some(
                id =>
                  id === data['campaign_id'] &&
                  data['campaign_name']
                    .toLowerCase()
                    .includes(campaignSearch.toLowerCase()) &&
                  data['adset_name']
                    .toLowerCase()
                    .includes(adsetSearch.toLowerCase()) &&
                  data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
              )
            )
            .filter(data =>
              selectedASIDS.some(
                id =>
                  id === data['adset_id'] &&
                  data['campaign_name']
                    .toLowerCase()
                    .includes(campaignSearch.toLowerCase()) &&
                  data['adset_name']
                    .toLowerCase()
                    .includes(adsetSearch.toLowerCase()) &&
                  data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
              )
            )
        : selectedLevel === 'Ad' &&
          selectedCAIDS.length >= 1 &&
          selectedASIDS.length === 0
        ? statusFiltered.filter(data =>
            selectedCAIDS.some(
              id =>
                id === data['campaign_id'] &&
                data['campaign_name']
                  .toLowerCase()
                  .includes(campaignSearch.toLowerCase()) &&
                data['adset_name']
                  .toLowerCase()
                  .includes(adsetSearch.toLowerCase()) &&
                data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
            )
          )
        : selectedLevel === 'Ad' &&
          selectedCAIDS.length === 0 &&
          selectedASIDS.length >= 1
        ? statusFiltered.filter(data =>
            selectedASIDS.some(
              id =>
                id === data['adset_id'] &&
                data['campaign_name']
                  .toLowerCase()
                  .includes(campaignSearch.toLowerCase()) &&
                data['adset_name']
                  .toLowerCase()
                  .includes(adsetSearch.toLowerCase()) &&
                data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
            )
          )
        : statusFiltered.filter(
            data =>
              data['campaign_name']
                .toLowerCase()
                .includes(campaignSearch.toLowerCase()) &&
              data['adset_name']
                .toLowerCase()
                .includes(adsetSearch.toLowerCase()) &&
              data['ad_name'].toLowerCase().includes(adSearch.toLowerCase())
          )

    var groupedData = groupByLevel(filteredData, selectedLevel)

    // groupedData = addBudgetsToData(groupedData, selectedLevel, adsetBudgets)

    if (selectedLevel.toLowerCase() == 'ad' && aggregateAds) {
      groupedData = groupByAdName(groupedData)
    }

    var dataAddedColumns = calculateNewColumns(groupedData)

    function uniqueArray (arr) {
      const unique = []

      for (const item of arr) {
        if (!unique.includes(item)) {
          unique.push(item)
        }
      }

      return unique
    }

    setSelectedCAIDCount(
      isNumber(uniqueArray(selectedCAIDS).length) ? selectedCAIDS.length : ''
    )

    setSelectedASIDCount(
      isNumber(uniqueArray(selectedASIDS).length) ? selectedASIDS.length : ''
    )

    setSelectedAIDCount(
      isNumber(uniqueArray(selectedAIDS).length) ? selectedAIDS.length : ''
    )

    var sortedData = sortType
      ? [...dataAddedColumns].sort((a, b) => {
          if (a[sortHeader] < b[sortHeader]) {
            return -1
          } else if (a[sortHeader] > b[sortHeader]) {
            return 1
          }
          return 0
        })
      : [...dataAddedColumns].sort((a, b) => {
          if (a[sortHeader] < b[sortHeader]) {
            return 1
          } else if (a[sortHeader] > b[sortHeader]) {
            return -1
          }
          return 0
        })
    var requiredColumnOrder = [
      'campaign_name',
      'campaign_id',
      'adset_name',
      'adset_id',
      'ad_name',
      'ad_id',
      'status',
      'type',
      'deleted',
      'backburners',
      'no_show',
      'dnp',
      'pressure_sell',
      'upsells',
      'ultra_hot',
      'lead_lost',
      'nurturing',
      'sales_usa_cpa',
      'sales_usa_pre',
      'sales_uk_cpa',
      'h_good_usa_cpa',
      'h_good_usa_pre',
      'h_good_uk_cpa',
      'acquired_during_uk_cpa',
      'acquired_during_usa_cpa',
      'acquired_during_usa_pre',
      'leads_export',
      'qualified_export',
      'h_good_export',
      'h_ultra_export',
      'pending_export',
      'acquired_during_export',
      ...selectedColumnFilters
      // 'deleted',
      // ''
    ]
    var reorderedData = sortedData.map(row => {
      var rearrangedRow = {}

      setLoadingP(99)

      requiredColumnOrder.forEach(key => {
        if (row.hasOwnProperty(key)) {
          rearrangedRow[key] = row[key]
        }
      })
      return rearrangedRow
    })

    // Remove budget column if it's a GA account

    // if (selectedAdAccount.toLowerCase().includes('_ga')) {
    //   var reorderedData = reorderedData.map(row => {
    //     const { budget, ...newRow } = row
    //     return newRow
    //   })
    // }

    var ReminderRows =
      selectedLevel === 'Campaign'
        ? reorderedData.filter(row =>
            ReminderCAIDs.includes(Number(row.campaign_id))
          )
        : selectedLevel === 'Adset'
        ? reorderedData.filter(row =>
            ReminderASIDs.includes(Number(row.adset_id))
          )
        : reorderedData.filter(row => ReminderAIDs.includes(Number(row.ad_id)))

    var NonReminderRows =
      selectedLevel === 'Campaign'
        ? reorderedData.filter(
            row => !ReminderCAIDs.includes(Number(row.campaign_id))
          )
        : selectedLevel === 'Adset'
        ? reorderedData.filter(
            row => !ReminderASIDs.includes(Number(row.adset_id))
          )
        : reorderedData.filter(row => !ReminderAIDs.includes(Number(row.ad_id)))

    setPrimaryRangeProcessed(true)

    setData(ReminderRows.concat(NonReminderRows))

    setLoadingP(100)
  }, [
    selectedLevel,
    campaignSearch,
    adsetSearch,
    adSearch,
    sortHeaderDependency,
    tableData,
    advancedFilterSelections,
    advancedFilterModalShow,
    statusFilters,
    selectedColumnFilters,
    aggregateAds
  ])

  var [footerData, setFooterData] = useState([])
  useMemo(() => {
    const headerTotals = calculateHeaderTotals(sumEachColumn(Data))
    setHeaderTotal(headerTotals)

    function uniqueArray (arr) {
      const unique = []

      for (const item of arr) {
        if (!unique.includes(item)) {
          unique.push(item)
        }
      }

      return unique
    }

    setSelectedCAIDCount(
      isNumber(uniqueArray(selectedCAIDS).length) ? selectedCAIDS.length : ''
    )

    setSelectedASIDCount(
      isNumber(uniqueArray(selectedASIDS).length) ? selectedASIDS.length : ''
    )

    setSelectedAIDCount(
      isNumber(uniqueArray(selectedAIDS).length) ? selectedAIDS.length : ''
    )

    setFooterData([
      {
        ...headerTotals,
        campaign_name: 'TOTAL',
        Select: '',
        '': '',
        adset_name: 'TOTAL',
        ad_name: 'TOTAL'
        // budget: ''
      }
    ])
  }, [selectedRows, Data])

  const gridRef = useRef()

  function onFilterChanged () {
    const filteredNodes = gridRef.current.api.getRenderedNodes()
    const filteredData = filteredNodes.map(node => node.data)
    const headerTotals = calculateHeaderTotals(sumEachColumn(filteredData))
    setFooterData([
      {
        ...headerTotals,
        campaign_name: 'TOTAL',
        Select: '',
        '': '',
        adset_name: 'TOTAL',
        ad_name: 'TOTAL',
        budget: ''
      }
    ])
  }

  useEffect(() => {
    if (!compareRangeRequirement && PrimaryRangeProcessed) {
      setLoadingP(100)
    } else if (
      compareRangeRequirement &&
      PrimaryRangeProcessed &&
      CompareRangeProcessed
    ) {
      setLoadingP(100)
    } else {
      // No action needed
    }
  }, [loadingP, PrimaryRangeProcessed, CompareRangeProcessed])

  var [ComparisonData, setComparisonData] = useState([])

  const includeColumns =
    selectedLevel === 'Campaign'
      ? [...selectedColumnFilters, 'campaign_name'].filter(
          column => column !== 'days_active' && column !== 'conversion_period'
        )
      : selectedLevel === 'Adset'
      ? [...selectedColumnFilters, 'adset_name'].filter(
          column => column !== 'days_active' && column !== 'conversion_period'
        )
      : selectedLevel === 'Ad' && !aggregateAds
      ? [...selectedColumnFilters, 'ad_name']
      : [...selectedColumnFilters, 'ad_name'].filter(
          column => column !== 'conversion_period' && column !== 'budget'
        )

  // useEffect(() => {}, [showTableDataClickModal, clickedTableDataIDS]);

  var tableDataColumnsAllowedToBeClickable = [
    'acquisitions',
    'acquired_during',
    'h_good',
    'good_leads',
    'leads',
    'qualified'
  ]

  var [previewingAd, setPreviewingAd] = useState('')
  var [adAllTimeStats, setAdAllTimeStats] = useState('')

  function showAdPreview (adID) {
    var row = tableData.filter(row => row.ad_id === adID)

    setPreviewingAd(row[0]['ad_name'])

    setAdBeingPreviewed(adID)
    setShowAdPreviewModal(true)

    // setShowAdPreviewModal(true)

    axios
      .get(APIEndpointDomain + '/performance/ad/all-time-stats', {
        params: {
          lead_type: DataIsLeadType.toLowerCase(),
          ad_id: adID,
          account_name: selectedAdAccount
        },
        headers: {
          'Access-Control-Allow-Origin': '*',

          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        setAdAllTimeStats(response.data['data'])
      })
      .catch(error => {
        checkAuthenticationInResponse(Number(error.response.status))

        console.log(error)
        setAdAllTimeStats({})
      })

    axios
      .get(
        'https://graph.facebook.com/v17.0/' +
          adID.toString() +
          '/previews?ad_format=' +
          previewPlacement,
        {
          params: {
            access_token:
              'EAADfl4bMMZAYBO44V6EHEfNTyd4qCNZBXLXWut4waLp4XSxr1C43k36wxZAZAaVImvQZCSE7tZBtXUZCTbKaWWR4M7kLpZAY7ntAZBYhmCAeeNOtTSNPdWltiG9ZCL02xepkmhbaShBICQD2k3C356ZCdlzzjreKUwuODByC3E6taf4lLFePkAcAGeNN3hg'
          }
        }
      )
      .then(response => {
        const htmlString = response.data.data[0].body
        setPreviewHTML(htmlString)
      })
      .catch(error => {})
  }

  function changePreviewPlacement (placement) {
    setPreviewPlacement(placement)

    axios
      .get(
        'https://graph.facebook.com/v17.0/' +
          adBeingPreviewed.toString() +
          '/previews?ad_format=' +
          placement,
        {
          params: {
            access_token:
              'EAADfl4bMMZAYBOzcL8hGvpD1tKyjux9wBnmts3P9vndZCILjDm7t7QS0OLttf75tUZB0OBznDLh7CFWzOQp3oVtcx4NhpbfZCXpDKwx9esnh5NSXjZBSNtGg8xaPbNetOw0i1VxyhBCZANZCp9Ro0XRzQri4V9R6gXMIxpXpcTR1KzZA2s4PZAsiJQBui'
          }
        }
      )
      .then(response => {
        console.log(response)
        const htmlString = response.data.data[0].body
        setPreviewHTML(htmlString)
      })
      .catch(error => {
        console.log(error)
      })
  }

  var [healthOrPreview, setHealthOrPreview] = useState('Lifetime Stats')
  var [detailedInformationTab, setDetailedInformationTab] = useState('Stages')

  function updateFavoriteMessage (row, message) {
    const encodedData = encodeURIComponent(JSON.stringify(row))

    axios
      .get(APIEndpointDomain + '/performance/update-favorite-message', {
        params: {
          favorite: encodedData,
          new_message: message
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']

        setFavoritesData(favorites)
        setFavoriteCAIDs(response.data['caids'])
        setFavoriteASIDs(response.data['asids'])
        setFavoriteAIDs(response.data['aids'])
      })
      .catch(error => {
        console.log(error)
      })
  }

  function handleCellClick (data) {
    var column = data.colDef.field
    var row = data.data

    // column === "deleted" || column === "deleted_during"
    //   ? handleDeletionDataClick(
    //       row["campaign_id"],
    //       row["adset_id"],
    //       row["ad_id"],
    //       column
    //     )
    //   : column === "budget" &&
    //     selectedLevel === "Campaign" &&
    //     !selectedAdAccount.toLowerCase().includes("_ga")
    //   ? handleUpdateBudgetClick(
    //       row.campaign_id,
    //       campaignBudgets.filter((campaign) => {
    //         return campaign.folder_id == row.campaign_id;
    //       })[0]["budget"]
    //     )
    //   : column === "budget" &&
    //     selectedLevel === "Adset" &&
    //     !selectedAdAccount.toLowerCase().includes("_ga")
    //   ? handleUpdateBudgetClick(
    //       row.adset_id,
    //       adsetBudgets.filter((adset) => {
    //         return adset.folder_id == row.adset_id;
    //       })[0]["budget"]
    //     )
    //   : tableDataColumnsAllowedToBeClickable.includes(column)
    //   ? handleTableDataClick(
    //       row["campaign_id"],
    //       row["adset_id"],
    //       row["ad_id"],
    //       column
    //     )
    //   : column === "ad_name"
    //   ? showAdPreview(row["ad_id"])
    //   : selectedLevel === "Ad"
    //   ? null
    //   : column === "campaign_id" || column === "adset_id" || column === "ad_id"
    //   ? null
    //   : handleRowDataClick({
    //       campaignID:
    //         selectedLevel === "Campaign"
    //           ? row["campaign_id"]
    //           : selectedLevel === "Adset"
    //           ? tableData.find(
    //               (each_row) => each_row.adset_id == row["adset_id"]
    //             )["campaign_id"]
    //           : null,
    //       adsetID: selectedLevel === "Adset" ? row["adset_id"] : null,
    //       adID: selectedLevel === "Ad" ? row["ad_id"] : null,
    //     });

    if (column === 'deleted' || column === 'deleted_during') {
      handleDeletionDataClick(
        row['campaign_id'],
        row['adset_id'],
        row['ad_id'],
        column
      )
    }

    // else if (
    //   column === 'budget' &&
    //   selectedLevel === 'Campaign' &&
    //   !selectedAdAccount.toLowerCase().includes('_ga')
    // ) {
    //   handleUpdateBudgetClick(
    //     row.campaign_id,
    //     campaignBudgets.find(campaign => campaign.folder_id == row.campaign_id)[
    //       'budget'
    //     ]
    //   )
    // }

    // else if (
    //   column === 'budget' &&
    //   selectedLevel === 'Adset' &&
    //   !selectedAdAccount.toLowerCase().includes('_ga')
    // ) {
    //   handleUpdateBudgetClick(
    //     row.adset_id,
    //     adsetBudgets.find(adset => adset.folder_id == row.adset_id)['budget']
    //   )
    // } else if (tableDataColumnsAllowedToBeClickable.includes(column)) {
    //   handleTableDataClick(
    //     row['campaign_id'],
    //     row['adset_id'],
    //     row['ad_id'],
    //     column
    //   )
    // }
    else if (column === 'ad_name') {
      showAdPreview(row['ad_id'])
    } else if (selectedLevel === 'Ad') {
      // Do nothing
    } else if (
      column === 'campaign_id' ||
      column === 'adset_id' ||
      column === 'ad_id'
    ) {
      // Do nothing
    } else {
      // handleRowDataClick({
      //   campaignID:
      //     selectedLevel === 'Campaign'
      //       ? row['campaign_id']
      //       : selectedLevel === 'Adset'
      //       ? tableData.find(eachRow => eachRow.adset_id == row['adset_id'])[
      //           'campaign_id'
      //         ]
      //       : null,
      //   adsetID: selectedLevel === 'Adset' ? row['adset_id'] : null,
      //   adID: selectedLevel === 'Ad' ? row['ad_id'] : null
      // })
    }
  }

  function cellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = params.data[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    var invalid_count = params.data['deleted']
      ? params.data['deleted']
      : 0 + params.data['dnp']
      ? params.data['dnp']
      : 0 + params.data['no_show']
      ? params.data['no_show']
      : 0
    var h_good_usa_cpa =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_usa_cpa']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'
    var h_good_usa_pre =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_usa_pre']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'
    var h_good_uk_cpa =
      params.data['h_good_usa_cpa'] +
      params.data['h_good_uk_cpa'] +
      params.data['h_good_usa_pre']
        ? Math.round(
            (100 * params.data['h_good_uk_cpa']) /
              (params.data['h_good_usa_cpa'] +
                params.data['h_good_uk_cpa'] +
                params.data['h_good_usa_pre'])
          ) + '%'
        : 0 + '%'

    var qualified_usa_cpa =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_usa_cpa']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'
    var qualified_usa_pre =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_usa_pre']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'
    var qualified_uk_cpa =
      params.data['sales_usa_cpa'] +
      params.data['sales_uk_cpa'] +
      params.data['sales_usa_pre']
        ? Math.round(
            (100 * params.data['sales_uk_cpa']) /
              (params.data['sales_usa_cpa'] +
                params.data['sales_uk_cpa'] +
                params.data['sales_usa_pre'])
          ) + '%'
        : 0 + '%'

    var indicator =
      pinned !== true &&
      showAvgComparisonIndicators &&
      averageStandardMetrics.includes(key) &&
      ![
        'quality',
        'lead_to_h_good_percentage',
        'lead_to_h_ultra_percentage',
        'h_good_percentage',
        'roi_during',
        'roi',
        'hook_rate',
        'hold_rate',
        'click_to_lead',
        'ctr'
      ].includes(key) &&
      (data >= averageStandards[key] || data == 0) ? (
        <Badge bg={'danger'}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Badge>
      ) : pinned !== true &&
        showAvgComparisonIndicators &&
        !compareRangeRequirement &&
        ![
          'quality',
          'lead_to_h_good_percentage',
          'lead_to_h_ultra_percentage',
          'h_good_percentage',
          'roi_during',
          'roi',
          'hook_rate',
          'hold_rate',
          'click_to_lead',
          'ctr'
        ].includes(key) &&
        averageStandardMetrics.includes(key) &&
        data < averageStandards[key] ? (
        <Badge bg={'success'}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Badge>
      ) : pinned !== true &&
        showAvgComparisonIndicators &&
        averageStandardMetrics.includes(key) &&
        [
          'quality',
          'lead_to_h_good_percentage',
          'lead_to_h_ultra_percentage',
          'h_good_percentage',
          'roi_during',
          'roi',
          'hook_rate',
          'hold_rate',
          'click_to_lead',
          'ctr'
        ].includes(key) &&
        (data >= averageStandards[key] || data == 0) ? (
        <Badge bg={'success'}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Badge>
      ) : pinned !== true &&
        showAvgComparisonIndicators &&
        !compareRangeRequirement &&
        [
          'quality',
          'lead_to_h_good_percentage',
          'lead_to_h_ultra_percentage',
          'h_good_percentage',
          'roi_during',
          'roi',
          'hook_rate',
          'hold_rate',
          'click_to_lead',
          'ctr'
        ].includes(key) &&
        averageStandardMetrics.includes(key) &&
        data < averageStandards[key] ? (
        <Badge bg={'danger'}>
          <FontAwesomeIcon icon={faArrowDown} />
        </Badge>
      ) : null

    if (key === 'cpl') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill className='mr-1'>
                Today - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_today'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_today'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_today'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                Yesterday - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce(
                          (acc, item) => acc + item['spend_yesterday'],
                          0
                        ) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce(
                          (acc, item) => acc + item['spend_yesterday'],
                          0
                        ) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce(
                          (acc, item) => acc + item['spend_yesterday'],
                          0
                        ) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                3 Days - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_3_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_3_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_3_days'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    }

    // else if (key === 'spend') {
    //   return (
    //     <p style={{ lineHeight: '1.8' }}>
    //       ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
    //       {showTrends && !pinned ? (
    //         <>
    //           <br></br>
    //           <Badge pill className='mr-1'>
    //             Today - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_today'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_today'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_today'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <Badge pill>
    //             Yesterday - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_yesterday'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_yesterday'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_yesterday'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <br></br>
    //           <Badge pill>
    //             3 Days - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_3_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_3_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_3_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <Badge pill>
    //             6 Days - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_6_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_6_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_6_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <br></br>
    //           <Badge pill>
    //             10 Days - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_10_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_10_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_10_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <Badge pill>
    //             21 Days - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_21_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend_21_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend_21_days'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //           <br></br>

    //           <Badge pill>
    //             All Time - {' ₹'}
    //             {selectedLevel == 'Campaign'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow =>
    //                         trendsRow['campaign_id'] == row.campaign_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Adset'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(
    //                       trendsRow => trendsRow['adset_id'] == row.adset_id
    //                     )
    //                     .reduce((acc, item) => acc + item['spend'], 0)
    //                 ).toLocaleString('en-IN')
    //               : selectedLevel == 'Ad'
    //               ? Math.round(
    //                   trendsData
    //                     .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
    //                     .reduce((acc, item) => acc + item['spend'], 0)
    //                 ).toLocaleString('en-IN')
    //               : null}
    //           </Badge>
    //         </>
    //       ) : null}
    //     </p>
    //   )
    // }
    else if (key === 'h_good') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          {Math.round(data).toLocaleString('en-IN')} {indicator}
          {!pinned && (
            <>
              <br></br>
              <Badge bg={'light'} pill>
                <CSVLink
                  filename={key + '-crm-export.csv'}
                  data={row['h_good_export']}
                >
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </CSVLink>
              </Badge>
            </>
          )}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                10 Days -{' '}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce(
                          (acc, item) => acc + item['good_leads_10_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce(
                          (acc, item) => acc + item['good_leads_10_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce(
                          (acc, item) => acc + item['good_leads_10_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time -{' '}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce(
                          (acc, item) => acc + item['good_leads_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce(
                          (acc, item) => acc + item['good_leads_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce(
                          (acc, item) => acc + item['good_leads_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'spend') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned && showSpendTrends ? (
            <>
              <br></br>
              <Badge pill>
                Today -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_today'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_today'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_today'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                Yesterday -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_yesterday'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_yesterday'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_yesterday'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                3 Days -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_3_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                6 Days -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_6_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_6_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_6_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                10 Days -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_10_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_10_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_10_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                15 Days -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_15_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_15_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_15_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                21 Days -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_21_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_21_days'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_21_days'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time -{' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0)
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0)
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'h_ultra') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          {Math.round(data).toLocaleString('en-IN')} {indicator}
          {!pinned && (
            <>
              <br></br>
              <Badge bg={'light'} pill>
                <CSVLink
                  filename={key + '-crm-export.csv'}
                  data={row['h_ultra_export']}
                >
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                </CSVLink>
              </Badge>
            </>
          )}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                15 Days -{' '}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_15_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_15_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_15_days'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time -{' '}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce(
                          (acc, item) => acc + item['ultra_hots_all_time'],
                          0
                        )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'cpql') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                6 Days - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_6_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_6_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_6_days'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'h_cpgl' || key === 'cpgl_historic') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                10 Days - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_10_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['good_leads_10_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_10_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['good_leads_10_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_10_days'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['good_leads_10_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['good_leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['good_leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['good_leads_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'cpuhl_h') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                15 Days - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_15_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_15_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_15_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_15_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_15_days'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_15_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['ultra_hots_all_time'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'cpa_during') {
      return (
        <p style={{ lineHeight: '1.8' }}>
          ₹{Math.round(data).toLocaleString('en-IN')} {indicator}
          {showTrends && !pinned ? (
            <>
              <br></br>
              <Badge pill>
                21 Days - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend_21_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend_21_days'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend_21_days'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
              <br></br>
              <Badge pill>
                All Time - {' ₹'}
                {selectedLevel == 'Campaign'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow =>
                            trendsRow['campaign_id'] == row.campaign_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Adset'
                  ? Math.round(
                      trendsData
                        .filter(
                          trendsRow => trendsRow['adset_id'] == row.adset_id
                        )
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : selectedLevel == 'Ad'
                  ? Math.round(
                      trendsData
                        .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                        .reduce((acc, item) => acc + item['spend'], 0) /
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                    ).toLocaleString('en-IN')
                  : null}
              </Badge>
            </>
          ) : null}
        </p>
      )
    } else if (key === 'campaign_id' || key === 'adset_id' || key === 'ad_id') {
      return <p contentEditable>{data}</p>
    } else if (key === 'leads') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}

            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  Invalid - {invalid_count}
                </Badge>{' '}
                {!['jobs', 'gmat'].includes(DataIsLeadType.toLowerCase()) && (
                  <Badge
                    bg='#fad4cd'
                    style={{
                      fontSize: '9.5px',
                      background: '#fad4cd',
                      color: 'black'
                    }}
                  >
                    BB - {params.data['backburners']}
                  </Badge>
                )}
                {/* <br></br>
        <Badge bg="#fad4cd" style={{ fontSize: "9.5px", background: "#fad4cd", color: "black"}}>DNP - {params.data['dnp']}</Badge>
        {" "}
        <Badge bg="#fad4cd" style={{ fontSize: "9.5px", background: "#fad4cd", color: "black"}}>NoShow - {params.data['no_show']}</Badge> */}
              </>
            ) : null}
            {!pinned && (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['leads_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            )}
            {showTrends && !pinned ? (
              <>
                <br></br>
                <Badge pill className='mr-1'>
                  Today -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce((acc, item) => acc + item['leads_today'], 0)
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  Yesterday -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['leads_yesterday'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  3 Days -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce((acc, item) => acc + item['leads_3_days'], 0)
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  All Time -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['leads_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (
      key === 'qualified' &&
      ['gmat'].includes(DataIsLeadType.toLowerCase())
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}

            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  PS - {params.data['pressure_sell']}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  UHL - {params.data['ultra_hot']}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  Upsell - {params.data['upsells']}
                </Badge>
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  Lost - {params.data['lead_lost']}
                </Badge>
              </>
            ) : null}

            {!pinned && (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['qualified_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            )}

            {showTrends && !pinned ? (
              <>
                <br></br>
                <Badge pill>
                  6 Days -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  All Time -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (
      key === 'qualified' &&
      ['premium', 'jobs'].includes(DataIsLeadType.toLowerCase())
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  PS - {params.data['pressure_sell']}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  UHL - {params.data['ultra_hot']}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  Nurture - {params.data['nurturing']}
                </Badge>
                <Badge
                  bg='#fad4cd'
                  style={{
                    fontSize: '9.5px',
                    background: '#fad4cd',
                    color: 'black'
                  }}
                >
                  Lost - {params.data['lead_lost']}
                </Badge>
              </>
            ) : null}

            {!pinned && (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['qualified_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            )}

            {showTrends && !pinned ? (
              <>
                <br></br>
                <Badge pill>
                  6 Days -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_6_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  All Time -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['qualified_all_time'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'qualified' && DataIsLeadType.toLowerCase() == 'cpa') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {qualified_usa_cpa}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {qualified_uk_cpa}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {qualified_usa_pre}
                </Badge>
              </>
            ) : null}

            {!pinned && (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['qualified_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            )}
          </p>
        </>
      )
    } else if (
      key === 'real_acquisitions_during' &&
      DataIsLeadType.toLowerCase() == 'cpa'
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {row['acquired_during_usa_cpa']}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {row['acquired_during_uk_cpa']}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {row['acquired_during_usa_pre']}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (
      key === 'acquired_during' &&
      DataIsLeadType.toLowerCase() == 'cpa'
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  avg. time - {Math.round(row['avg_conversion_period'])} days
                </Badge>{' '}
              </>
            ) : null}
          </p>
        </>
      )
    } else if (
      key === 'acquired_during' &&
      ['premium', 'jobs', 'gmat', 'premium + cpa'].includes(
        DataIsLeadType.toLowerCase()
      )
    ) {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {Math.round(data)}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  avg. time - {Math.round(row['avg_conversion_period'])} days
                </Badge>{' '}
              </>
            ) : null}
            {!pinned && (
              <>
                <br></br>
                <Badge bg={'light'} pill>
                  <CSVLink
                    filename={key + '-crm-export.csv'}
                    data={row['acquired_during_export']}
                  >
                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  </CSVLink>
                </Badge>
              </>
            )}
            {showTrends && !pinned ? (
              <>
                <br></br>
                <Badge pill>
                  21 Days -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) =>
                              acc + item['acquired_during_21_days'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
                <br></br>
                <Badge pill>
                  All Time -{' '}
                  {selectedLevel == 'Campaign'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow =>
                              trendsRow['campaign_id'] == row.campaign_id
                          )
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Adset'
                    ? Math.round(
                        trendsData
                          .filter(
                            trendsRow => trendsRow['adset_id'] == row.adset_id
                          )
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : selectedLevel == 'Ad'
                    ? Math.round(
                        trendsData
                          .filter(trendsRow => trendsRow['ad_id'] == row.ad_id)
                          .reduce(
                            (acc, item) => acc + item['acquired_during'],
                            0
                          )
                      ).toLocaleString('en-IN')
                    : null}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'h_good' && DataIsLeadType.toLowerCase() == 'cpa') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            {data}
            {showAvgComparisonIndicators && !pinned ? (
              <>
                <br></br>
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  us cpa - {h_good_usa_cpa}
                </Badge>{' '}
                <Badge bg='success' style={{ fontSize: '9.5px' }}>
                  uk cpa - {h_good_uk_cpa}
                </Badge>
                <br></br>
                <Badge bg='primary' style={{ fontSize: '9.5px' }}>
                  us pre - {h_good_usa_pre}
                </Badge>
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'budget') {
      return (
        <p>
          {'₹' + Math.round(data).toLocaleString('en-IN')}
          {'  '}
          {/* <FontAwesomeIcon
            className='fa-regular fa-star'
            icon={faPenToSquare}
          /> */}
        </p>
      )
    } else if (currencyMetrics.includes(key)) {
      return (
        <p>
          ₹{Math.round(data).toLocaleString('en-IN') + '  '}
          {indicator}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key !== 'ctr') {
      return (
        <p>
          {Math.round(data).toLocaleString('en-IN')}%{'  '}
          {indicator}
        </p>
      )
    } else if (percentageMetrics.includes(key) && key == 'ctr') {
      return (
        <p>
          {data.toLocaleString('en-IN')}%{'  '}
          {indicator}
        </p>
      )
    } else {
      return <p>{data}</p>
    }
  }

  function NameCellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = row[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned === true) {
      return <></>
    } else if (key === 'campaign_name') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            <strong>{data + ' '}</strong>
            {!compareRangeRequirement ? (
              <>
                <br></br>
                {/* <OverlayTrigger
                  placement="bottom"
                  delay={{
                    show: 1500,
                    hide: 400,
                  }}
                  overlay={renderActiveAdsetNamesTooltip(row.campaign_id)}
                > */}
                <Badge bg='success' pill>
                  {'Adsets -> ' +
                    activeAdsets.filter(adset_id =>
                      tableData
                        .filter(
                          thisRow => thisRow.campaign_id === row.campaign_id
                        )
                        .some(thisRow => thisRow.adset_id === adset_id)
                    ).length}
                </Badge>
                {/* </OverlayTrigger>{" "}
                <OverlayTrigger
                  placement="bottom"
                  delay={{
                    show: 1500,
                    hide: 400,
                  }}
                  overlay={renderActiveAdNamesTooltip(row.campaign_id)}
                > */}
                <Badge bg='success'>
                  {'Ads -> ' +
                    tableData.filter(
                      thisRow =>
                        thisRow.campaign_id === row.campaign_id &&
                        activeAds.includes(thisRow.ad_id)
                    ).length}
                </Badge>
                {/* </OverlayTrigger> */}
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'adset_name') {
      return (
        <>
          <p style={{ lineHeight: '1.8' }}>
            <strong>{data + ' '}</strong>
            <br></br>
            <Badge bg='primary'>{'#' + row.campaign_name}</Badge>{' '}
            {!compareRangeRequirement ? (
              <>
                <br></br>
                {/* <OverlayTrigger
                  placement="bottom"
                  delay={{
                    show: 1500,
                    hide: 400,
                  }}
                  overlay={renderActiveAdNamesTooltip(row.adset_id)}
                > */}
                <Badge bg='success'>
                  {'Ads -> ' +
                    tableData.filter(
                      thisRow =>
                        thisRow.adset_id === row.adset_id &&
                        activeAds.includes(thisRow.ad_id)
                    ).length}
                </Badge>
                {/* </OverlayTrigger> */}
              </>
            ) : null}
          </p>
        </>
      )
    } else if (key === 'ad_name') {
      return (
        <>
          <p
            onClick={() => showAdPreview(row['ad_id'])}
            style={{ lineHeight: '1.8' }}
          >
            <strong>{data + ' '}</strong>
            <br></br>
            {!aggregateAds ? (
              <>
                <Badge bg='dark'>{row.days_active + ' Days'}</Badge>{' '}
                <Badge bg='primary'>{'#' + row.type}</Badge> <br></br>
                <Badge bg='light'>{'#' + row.campaign_name}</Badge>{' '}
                <Badge bg='light'>{'#' + row.adset_name}</Badge>
              </>
            ) : (
              <>
                {' '}
                <Badge bg='dark'>{getMaxDaysActive(data) + ' Days'}</Badge>
              </>
            )}
          </p>
        </>
      )
    }
  }

  const getMaxDaysActive = adName => {
    const filteredData = tableData.filter(dataRow => dataRow.ad_name === adName)
    const idToDaysActiveMap = filteredData.reduce((acc, dataRow) => {
      acc[dataRow.ad_id] = (acc[dataRow.ad_id] || 0) + dataRow.days_active
      return acc
    }, {})

    return Math.max(...Object.values(idToDaysActiveMap))
  }

  function checkboxCellRenderer (params) {
    var key = params.colDef['field']
    var row = params.data
    var data = row[key]
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        <input
          checked={
            selectedLevel === 'Campaign' &&
            selectedCAIDS.includes(row['campaign_id'])
              ? true
              : selectedLevel === 'Adset' &&
                selectedASIDS.includes(row['adset_id'])
              ? true
              : selectedLevel === 'Ad' && selectedAIDS.includes(row['ad_id'])
              ? true
              : false
          }
          onClick={() => {
            selectingRows({
              campaignID:
                selectedLevel === 'Campaign' ? row['campaign_id'] : null,
              adsetID: selectedLevel === 'Adset' ? row['adset_id'] : null,
              adID: selectedLevel === 'Ad' ? row['ad_id'] : null
            })
          }}
          type='checkbox'
          disabled={consolidationType === 'name' ? true : false}
        ></input>
        // </Button>
      )
    } else {
      return <></>
    }
  }

  var adsTaggedIDs = adsTagged.map(ad => ad['ad_id'])

  function selectionHeaderRenderer (params) {
    return (
      <div
        onClick={consolidationType !== 'name' ? selectUnselectAll : null}
        key={10000000000}
        className='first-column'
        style={{
          textAlign: 'center'
        }}
      >
        {selectedRows.length === 0 ? 'Select  All' : 'Unselect'}
      </div>
    )
  }

  function ButtonsCellRenderer (params) {
    var row = params.data
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (!pinned) {
      return (
        <p
          style={{
            textAlign: 'center'
          }}
          className={
            (selectedLevel === 'Campaign' &&
              ReminderCAIDs.includes(Number(row['campaign_id']))) ||
            (selectedLevel === 'Adset' &&
              ReminderASIDs.includes(Number(row['adset_id']))) ||
            (selectedLevel === 'Ad' &&
              ReminderAIDs.includes(Number(row['ad_id'])))
              ? 'half-column table-reminder'
              : selectedLevel === 'Campaign' &&
                selectedCAIDS.includes(row.campaign_id)
              ? 'half-column table-selected-row'
              : selectedLevel === 'Adset' &&
                selectedASIDS.includes(row.adset_id)
              ? 'half-column table-selected-row'
              : selectedLevel === 'Ad' && selectedAIDS.includes(row.ad_id)
              ? 'half-column table-selected-row'
              : (selectedLevel === 'Ad' && !activeAds.includes(row.ad_id)) ||
                (selectedLevel === 'Adset' &&
                  !activeAdsets.includes(row.adset_id)) ||
                (selectedLevel === 'Campaign' &&
                  !activeCampaigns.includes(row.campaign_id))
              ? 'half-column table-paused'
              : 'half-column table-light'
          }
        >
          {' '}
          {!selectedAdAccount.includes('_GA') && !compareRangeRequirement ? (
            <>
              {/* <OverlayTrigger
                placement="bottom"
                delay={{ show: 1500, hide: 400 }}
                overlay={renderButtonTooltip(
                  "Turn on or off the campaign, adset or ad."
                )}
              > */}
              {/* <Switch
                checked={
                  selectedLevel === "Campaign" &&
                  !selectedAdAccount.includes("_GA") &&
                  activeCampaigns.includes(row.campaign_id)
                    ? true
                    : selectedLevel === "Adset" &&
                      !selectedAdAccount.includes("_GA") &&
                      activeAdsets.includes(row.adset_id)
                    ? true
                    : selectedLevel === "Ad" &&
                      !selectedAdAccount.includes("_GA") &&
                      activeAds.includes(row.ad_id)
                    ? true
                    : false
                }
                color="primary"
                onClick={() =>
                  onAdStatusSwitchClick(
                    selectedLevel === "Campaign"
                      ? row.campaign_id
                      : selectedLevel === "Adset"
                      ? row.adset_id
                      : selectedLevel === "Ad"
                      ? row.ad_id
                      : null,
                    selectedLevel === "Campaign"
                      ? row.campaign_name
                      : selectedLevel === "Adset"
                      ? row.adset_name
                      : selectedLevel === "Ad"
                      ? row.ad_name
                      : null
                  )
                }
              ></Switch> */}
              {/* </OverlayTrigger> */}
              {/* <br></br> */}
            </>
          ) : null}
          <>
            {/* <OverlayTrigger
                placement="bottom"
                delay={{ show: 1500, hide: 400 }}
                overlay={renderButtonTooltip("Set, view or delete a reminder.")}
              > */}
            <Button
              onClick={() => {
                selectedLevel === 'Campaign'
                  ? setReminderAdID(row.campaign_id)
                  : selectedLevel === 'Adset'
                  ? setReminderAdID(row.adset_id)
                  : setReminderAdID(row.ad_id)
                setShowReminderModal(true)
              }}
              variant={
                (selectedLevel === 'Campaign' &&
                  ReminderCAIDs.includes(Number(row['campaign_id']))) ||
                (selectedLevel === 'Adset' &&
                  ReminderASIDs.includes(Number(row['adset_id']))) ||
                (selectedLevel === 'Ad' &&
                  ReminderAIDs.includes(Number(row['ad_id'])))
                  ? 'danger'
                  : 'warning'
              }
              className='alarm-badge'
            >
              <FontAwesomeIcon
                shake={
                  (selectedLevel === 'Campaign' &&
                    ReminderCAIDs.includes(Number(row['campaign_id']))) ||
                  (selectedLevel === 'Adset' &&
                    ReminderASIDs.includes(Number(row['adset_id']))) ||
                  (selectedLevel === 'Ad' &&
                    ReminderAIDs.includes(Number(row['ad_id'])))
                }
                icon={faBell}
              />
            </Button>
            {/* </OverlayTrigger> */}
          </>
          {/* <br></br> */}
          {'   '}
          {/* <OverlayTrigger
            placement="bottom"
            delay={{ show: 1500, hide: 400 }}
            overlay={renderButtonTooltip(
              "Bookmark this campaign/adset/ad to your custom report as static data!"
            )}
          > */}
          <Button
            variant={
              (selectedLevel === 'Campaign' &&
                favoritesData.filter(
                  favorite =>
                    favorite['favorites']['campaign_id'] === row.campaign_id &&
                    favorite['favorites']['start_date'] == startDate &&
                    favorite['favorites']['end_date'] == endDate
                ).length > 0) ||
              (selectedLevel === 'Adset' &&
                favoritesData.filter(
                  favorite =>
                    favorite['favorites']['adset_id'] === row.adset_id &&
                    favorite['favorites']['start_date'] == startDate &&
                    favorite['favorites']['end_date'] == endDate
                ).length > 0) ||
              (selectedLevel === 'Ad' &&
                favoritesData.filter(
                  favorite =>
                    favorite['favorites']['ad_id'] === row.ad_id &&
                    favorite['favorites']['start_date'] == startDate &&
                    favorite['favorites']['end_date'] == endDate
                ).length > 0)
                ? 'success'
                : 'dark'
            }
            onClick={() =>
              selectedLevel === 'Campaign'
                ? saveFavorite(row.campaign_id, selectedLevel)
                : selectedLevel === 'Adset'
                ? saveFavorite(row.adset_id, selectedLevel)
                : saveFavorite(row.ad_id, selectedLevel)
            }
          >
            {(selectedLevel === 'Campaign' &&
              favoritesData.filter(
                favorite =>
                  favorite['favorites']['campaign_id'] === row.campaign_id &&
                  favorite['favorites']['start_date'] == startDate &&
                  favorite['favorites']['end_date'] == endDate
              ).length > 0) ||
            (selectedLevel === 'Adset' &&
              favoritesData.filter(
                favorite =>
                  favorite['favorites']['adset_id'] === row.adset_id &&
                  favorite['favorites']['start_date'] == startDate &&
                  favorite['favorites']['end_date'] == endDate
              ).length > 0) ||
            (selectedLevel === 'Ad' &&
              favoritesData.filter(
                favorite =>
                  favorite['favorites']['ad_id'] === row.ad_id &&
                  favorite['favorites']['start_date'] == startDate &&
                  favorite['favorites']['end_date'] == endDate
              ).length > 0) ? (
              <FontAwesomeIcon className='fa-solid fa-star' icon={faStar} />
            ) : (
              <FontAwesomeIcon
                className='fa-regular fa-star'
                icon={faStarHalf}
              />
            )}
          </Button>
          {/* </OverlayTrigger> */}
          {selectedLevel === 'Ad' ? (
            <>
              <br></br>

              <Button
                variant={
                  adsTaggedIDs.includes(Number(row.ad_id))
                    ? 'success'
                    : 'danger'
                }
                onClick={() => {
                  setTagsToShowOnAdTagModal(
                    adsTagged.find(ad => ad.ad_id == Number(row.ad_id))
                      ? adsTagged.find(ad => ad.ad_id == Number(row.ad_id))[
                          'tag_name'
                        ]
                      : ''
                  )

                  setShowAdTagAssignModalFor({
                    ad_name: row.ad_name,
                    ad_id: row.ad_id
                  })
                  setShowIndividualAdTagAssignModal(true)
                }}
              >
                {!adsTaggedIDs.includes(Number(row.ad_id)) ? (
                  <FontAwesomeIcon icon={faHashtag}></FontAwesomeIcon>
                ) : (
                  <div style={{ fontSize: '10px' }}>
                    #{adsTagged.find(ad => ad.ad_id == row.ad_id)['tag_name']}
                  </div>
                )}
              </Button>
            </>
          ) : null}
        </p>
      )
    } else {
      return <></>
    }
  }

  var [headers, setHeaders] = useState([])
  var [postMoveHeaders, setPostMoveHeaders] = useState([])
  var [columnOrder, setColumnOrder] = useState([])

  useMemo(() => {
    try {
      var heads =
        Data && Data.length > 0
          ? Object.keys(Data[0])
              .filter(key => key != 'avg_conversion_period')
              .map((key, index) => (includeColumns.includes(key) ? key : null))
              .filter(key => key !== null)
          : []

      heads = heads.map(header =>
        ['campaign_name', 'adset_name', 'ad_name'].includes(header)
          ? {
              headerName: String(header).replace(/_/g, ' '),
              field: header,
              onCellClicked: handleCellClick,
              cellRenderer: NameCellRenderer,
              filter: 'agTextColumnFilter',
              floatingFilter: false,
              filterParams: {
                caseSensitive: false,
                defaultOption: 'contains'
              },
              wrapText: true,
              autoHeight: true,
              width: 300,
              pinned: ['campaign_name', 'adset_name', 'ad_name'].includes(
                header
              )
                ? 'left'
                : false
            }
          : {
              headerName: String(header).replace(/_/g, ' '),
              field: header,
              filter: 'agNumberColumnFilter',
              floatingFilter: true,
              onCellClicked: handleCellClick,
              cellRenderer: cellRenderer,
              autoHeight: true,

              autoWidth: ['leads', 'qualified'].includes(header) ? true : false,
              // width: ['leads', 'qualified', 'h_good'].includes(header) ? 160 : 130,
              pinned: ['budget', 'spend'].includes(header) ? 'left' : false
            }
      )

      heads =
        aggregateAds && selectedLevel == 'Ad'
          ? [...heads]
          : [
              {
                field: 'select',
                cellRenderer: checkboxCellRenderer,
                filter: false,
                rowDrag: true,
                sortable: false,
                headerComponent: selectionHeaderRenderer,

                wrapText: true,
                autoHeight: true,
                width: 100,
                pinned: 'left'
              },
              {
                field: '',
                cellRenderer: ButtonsCellRenderer,
                rowDrag: false,
                sortable: false,
                autoHeight: true,
                width: 180,
                pinned: 'left'
              },

              ...heads
            ]

      setHeaders(Data && Data.length > 0 ? heads : [])
    } finally {
      setPostMoveHeaders(Data && Data.length > 0 ? heads : [])
    }
  }, [
    Data,
    selectedRows,
    favoriteAIDs,
    favoriteASIDs,
    favoriteCAIDs,
    showAvgComparisonIndicators,
    showTrends,
    showSpendTrends
  ])

  // useEffect(() => {
  //   setPostMoveHeaders(postMoveHeaders)
  // }, [])

  var [selectedColumnFilterHistory, setSelectedColumnFilterHistory] = useState([
    ...selectedColumnFilters
  ])
  useEffect(() => {
    var recentlyChangedColumn = selectedColumnFilters.at(-1)

    if (recentlyChangedColumn != 'days_active') {
      setSelectedColumnFilterHistory([
        ...selectedColumnFilterHistory,
        selectedColumnFilters
      ])
    }
  }, [selectedColumnFilters.length])

  useEffect(() => {
    var recentlyChangedColumn = selectedColumnFilters.at(-1)

    if (
      selectedColumnFilterHistory &&
      selectedColumnFilterHistory.length > 0 &&
      selectedColumnFilterHistory.at(-2).length > selectedColumnFilters.length
    ) {
      if (recentlyChangedColumn != 'days_active') {
        setPostMoveHeaders(
          postMoveHeaders.filter(header =>
            selectedColumnFilters.includes(header.field)
          )
        )
      }
    } else if (
      selectedColumnFilterHistory &&
      selectedColumnFilterHistory.length > 0 &&
      selectedColumnFilterHistory.at(-2).length < selectedColumnFilters.length
    ) {
      if (recentlyChangedColumn != 'days_active') {
        setPostMoveHeaders([
          ...postMoveHeaders,
          {
            headerName: String(recentlyChangedColumn).replace(/_/g, ' '),
            field: recentlyChangedColumn,
            filter: 'agNumberColumnFilter',
            floatingFilter: true,
            onCellClicked: handleCellClick,
            cellRenderer: cellRenderer,
            autoHeight: true,

            autoWidth: ['leads', 'qualified'].includes(recentlyChangedColumn)
              ? true
              : false,
            // width: ['leads', 'qualified', 'h_good'].includes(recentlyAddedColumn) ? 160 : 130,
            pinned: ['budget', 'spend'].includes(recentlyChangedColumn)
              ? 'left'
              : false
          }
        ])
      }
    }
  }, [selectedColumnFilterHistory])

  function onColumnMoved () {
    try {
      var params = gridRef.current
    } catch {
      return
    }

    try {
      const newColumnState = params.columnApi.getColumnState()
      const newColumnDefs = newColumnState.map(colState => {
        const colDef = headers.find(col => col.field === colState.colId)
        return {
          ...colDef,
          pinned: colState.pinned,
          sort: colState.sort,
          sortIndex: colState.sortIndex,
          hide: colState.hide,
          width: colState.width
        }
      })

      var filteredColumnDefs = newColumnDefs.filter(def => {
        return (
          def['field'] !== 'select' &&
          def['field'] !== 'campaign_name' &&
          def['field'] !== 'adset_name' &&
          def['field'] !== 'ad_name' &&
          Object.keys(def).includes('field')
        )
      })

      if (filteredColumnDefs.length > 0) {
        var defaultColumnDefs = [
          {
            field: 'select',
            cellRenderer: checkboxCellRenderer,
            filter: false,
            rowDrag: true,
            sortable: false,
            headerComponent: selectionHeaderRenderer,

            wrapText: true,
            autoHeight: true,
            width: 100,
            pinned: 'left'
          },
          {
            field: '',
            cellRenderer: ButtonsCellRenderer,
            rowDrag: false,
            sortable: false,
            autoHeight: true,
            width: 180,
            pinned: 'left'
          }
        ]

        var levelBasedColumnDef =
          selectedLevel.toLowerCase() == 'campaign'
            ? {
                headerName: 'campaign_name'.replace(/_/g, ' '),
                field: 'campaign_name',
                onCellClicked: handleCellClick,
                cellRenderer: NameCellRenderer,
                filter: 'agTextColumnFilter',
                floatingFilter: false,
                filterParams: {
                  caseSensitive: false,
                  defaultOption: 'contains'
                },
                wrapText: true,
                autoHeight: true,
                width: 300,
                pinned: 'left'
              }
            : selectedLevel.toLowerCase() == 'adset'
            ? {
                headerName: 'adset_name'.replace(/_/g, ' '),
                field: 'adset_name',
                onCellClicked: handleCellClick,
                cellRenderer: NameCellRenderer,
                filter: 'agTextColumnFilter',
                floatingFilter: false,
                filterParams: {
                  caseSensitive: false,
                  defaultOption: 'contains'
                },
                wrapText: true,
                autoHeight: true,
                width: 300,
                pinned: 'left'
              }
            : {
                headerName: 'ad_name'.replace(/_/g, ' '),
                field: 'ad_name',
                onCellClicked: handleCellClick,
                cellRenderer: NameCellRenderer,
                filter: 'agTextColumnFilter',
                floatingFilter: false,
                filterParams: {
                  caseSensitive: false,
                  defaultOption: 'contains'
                },
                wrapText: true,
                autoHeight: true,
                width: 300,
                pinned: 'left'
              }

        var finalColumnDefs = [
          ...defaultColumnDefs,
          levelBasedColumnDef,
          ...filteredColumnDefs
        ]

        setPostMoveHeaders(finalColumnDefs)
        setColumnOrder(finalColumnDefs.map(state => state.colId))
      }
    } catch {
      return
    }
  }
  useEffect(() => {
    onColumnMoved()
  }, [headers, selectedLevel])

  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: true,
      cellStyle: { fontSize: '14px', textAlign: 'center' },
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filterParams: { newRowsAction: 'keep' }
    }
  }, [])

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const rowStyle = { background: '#black' }

  const getRowStyle = params => {
    var row = params.data

    var campaign_id = row['campaign_id']
    var adset_id = row['adset_id']
    var ad_id = row['ad_id']
    var pinned = Object.keys(params.node).includes('rowPinned') ? true : false

    if (pinned) {
      return { background: '#2c9c72', color: 'white', fontWeight: 'bold' }
    } else if (
      (selectedLevel === 'Campaign' &&
        ReminderCAIDs.includes(Number(campaign_id))) ||
      (selectedLevel === 'Adset' && ReminderASIDs.includes(Number(adset_id))) ||
      (selectedLevel === 'Ad' &&
        !aggregateAds &&
        ReminderAIDs.includes(Number(row['ad_id'])))
    ) {
      return { background: '#d4ff32' }
    } else if (
      selectedLevel === 'Campaign' &&
      selectedCAIDS.includes(campaign_id)
    ) {
      return { background: '#e2e8f0' }
    } else if (selectedLevel === 'Adset' && selectedASIDS.includes(adset_id)) {
      return { background: '#e2e8f0' }
    } else if (
      selectedLevel === 'Ad' &&
      !aggregateAds &&
      selectedAIDS.includes(row.ad_id)
    ) {
      return { background: '#e2e8f0' }
    } else if (
      (selectedLevel === 'Ad' && !aggregateAds && !activeAds.includes(ad_id)) ||
      (selectedLevel === 'Adset' && !activeAdsets.includes(adset_id)) ||
      (selectedLevel === 'Campaign' && !activeCampaigns.includes(campaign_id))
    ) {
      return { background: '#fecaca' }
    } else if (params.node.rowIndex % 2 !== 0) {
      return { background: '#f1f5f9' }
    } else if (params.node.rowIndex % 2 === 0) {
      return { background: '#f8fafc' }
    }
  }

  if (!['Campaign', 'Adset', 'Ad', 'Clusters'].includes(selectedLevel)) {
  }

  function saveFavorite (id, level, data) {
    console.log(data, 'logging data', Data)
    if (!data) {
      var dataForID =
        level == 'Campaign'
          ? Data.find(row => Number(row.campaign_id) == Number(id))
          : level == 'Adset'
          ? Data.find(row => row.adset_id == id)
          : Data.find(row => row.ad_id == id)

      dataForID['level'] = level

      if (
        level == 'Adset' &&
        adsetBudgets.map(budget_info => budget_info['folder_id']).includes(id)
      ) {
        dataForID['budget'] = adsetBudgets.find(adset => {
          return adset.folder_id == Number(id)
        })['budget']
      } else if (
        level == 'Campaign' &&
        campaignBudgets
          .map(budget_info => budget_info['folder_id'])
          .includes(id)
      ) {
        dataForID['budget'] = campaignBudgets.find(campaign => {
          return campaign.folder_id == Number(id)
        })['budget']
      } else {
        dataForID['budget'] = ''
      }

      dataForID['start_date'] = startDate
      dataForID['end_date'] = endDate

      level == 'Campaign'
        ? (dataForID['name'] = dataForID['campaign_name'])
        : level == 'Adset'
        ? (dataForID['name'] = dataForID['adset_name'])
        : (dataForID['name'] = dataForID['ad_name'])

      if (!dataForID['message']) {
        dataForID['message'] = ''
      }
    } else {
      if (!data['favorites']) {
        var dataForID = data
      } else {
        var dataForID = data['favorites']
      }
    }

    Object.keys(dataForID).forEach(key => {
      if (key.includes('_export')) {
        dataForID[key] = []
      }
    })

    const encodedData = encodeURIComponent(JSON.stringify(dataForID))

    axios
      .get(APIEndpointDomain + '/performance/save-favorite', {
        params: {
          level: level,
          id: id,
          favorite: encodedData
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
        const favorites = response.data['favorite']
        setFavoritesData(favorites)
        setFavoriteCAIDs(response.data['caids'])
        setFavoriteASIDs(response.data['asids'])
        setFavoriteAIDs(response.data['aids'])
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    setTotalsData(footerData)
  }, [footerData])

  return (
    <div
      className={
        selectedLevel !== 'Summary'
          ? 'pt-28 w-screen h-screen bg-opacity-0'
          : 'pt-16 w-screen h-screen bg-opacity-0'
      }
    >
      {tableData.length > 0 &&
      Data.length > 0 &&
      Data &&
      loadingP > 99 &&
      loadingP < 101 &&
      hideTableNameConsolidation === true &&
      !tableDataAPICalling &&
      ['Summary'].includes(selectedLevel) ? (
        <div className='bg-gray-50'></div>
      ) : tableData.length > 0 &&
        Data.length > 0 &&
        Data &&
        loadingP > 99 &&
        loadingP < 101 &&
        hideTableNameConsolidation === true &&
        !tableDataAPICalling &&
        ['Campaign', 'Adset', 'Ad'].includes(selectedLevel) ? (
        <>
          {/* <div className='h-screen w-screen'> */}
          <div style={gridStyle} className={'ag-theme-quartz'}>
            <AgGridReact
              rowData={Data}
              columnDefs={postMoveHeaders}
              defaultColDef={defaultColDef}
              rowDragManaged={true}
              rowSelection='multiple'
              rowDragMultiRow={true}
              rowStyle={rowStyle}
              getRowStyle={getRowStyle}
              sideBar={true}
              pinnedBottomRowData={footerData}
              onFilterChanged={onFilterChanged}
              ref={gridRef}
              onColumnMoved={onColumnMoved}
              onGridReady={onColumnMoved}
            />
            {/* </div> */}
          </div>
        </>
      ) : !['Campaign', 'Adset', 'Ad', 'Clusters'].includes(selectedLevel) ? (
        <FavoritesTable
          favorites={favoritesData}
          updateMessage={updateFavoriteMessage}
          saveFavorite={saveFavorite}
          setFavorites={setFavoritesData}
          setCampaignSearch={setCampaignSearch}
          setAdsetSearch={setAdsetSearch}
          setAdSearch={setAdSearch}
          setSelectedLevel={setSelectedLevel}
          selectedAdAccount={selectedAdAccount}
        />
      ) : isFirstLoad ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Click "GO" and start analyzing!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : loadingP < 99 || tableDataAPICalling ? (
        // <div className='snake-dcontainer'>
        //   <div className='snake-body-container'>
        // <div class="">
        // <div
        //   style={{ paddingBottom: '200px' }}
        //   class='flex justify-center items-center h-screen text-center'
        // >

        //   <AtomicSpinner
        //     electronColorPalette={['#404040', '#737373', '#A6A6A6']}
        //   ></AtomicSpinner>
        // </div>
        <MultiStepLoader
          className='bg-opacity-0'
          loadingStates={loadingStates}
          loading={tableDataAPICalling}
          duration={2100}
        ></MultiStepLoader>
      ) : !tableDataAPICalling && loadingP === 100 && tableData.length === 0 ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Server returned no data!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : !tableDataAPICalling && loadingP === 100 && Data.length === 0 ? (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'No data for applied filters!'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      ) : (
        <div className='flex justify-center items-center text-center h-full'>
          <TypewriterEffect
            className='text-4xl sm:text-5xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-400 to-neutral-500 py-8'
            words={'Unrecognized error'.split(' ').map(word => {
              return { text: word }
            })}
          ></TypewriterEffect>
        </div>
      )}
    </div>
  )
}

export default PerformanceTable
