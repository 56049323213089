import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import PerformanceMetaGADirectScreen from './screens/PerformanceMetaGADirectScreen'
import LoginScreen from './screens/LoginScreen'
import MarketingScreen from './screens/MarketingScreen'
import CommentManagementScreen from './screens/CommentManagementScreen'
import HomePage from './screens/HomePage'
import PerformanceCustomReportScreen from './screens/PerformanceCustomReportScreen'
import LeadFrequencyScreen from './screens/LeadFrequencyScreen'
import FourOhFour from './screens/404Screen'
import SlotsOpenScreen from './screens/SlotsOpenScreen'
import PerformanceGAIndirectScreen from './screens/PerformanceGAIndirectScreen'
import UserSessionSearchScreen from './screens/UserSessionSearchScreen'
import PerformanceMetaIndirectScreen from './screens/PerformanceMetaIndirectAdScreen'

function App () {
  var APIEndpointDomain = 'https://marketingapi.mim-essay.com/api'
  function checkAuthenticationInResponse (errorStatus) {
    if (errorStatus === 401) {
      localStorage.clear()
      window.location.href = '/login'
    } else {
    }
  }

  function requireAuth (element) {
    if (
      localStorage.getItem('access_token') !== null &&
      localStorage.getItem('access_token') !== undefined
    ) {
      return element
    }
    {
      return <Navigate to='/login' />
    }
  }

  function hideShowLogin (loginPage) {
    if (
      localStorage.getItem('access_token') !== null &&
      localStorage.getItem('access_token') !== undefined
    ) {
      return <Navigate to='/' />
    } else {
      return loginPage
    }
  }
  return (
    <Router>
      <Routes>
        <Route
          path='/performance/meta-direct'
          element={requireAuth(
            <PerformanceMetaGADirectScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
              channel={'meta'}
            />
          )}
        />
        <Route
          path='/performance/ga-direct'
          element={requireAuth(
            <PerformanceMetaGADirectScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
              channel={'google'}
            />
          )}
        />
        <Route
          path='/comment-management'
          element={requireAuth(
            <div
              style={{
                backgroundColor: '#e8ebe9'
              }}
            >
              <CommentManagementScreen
                checkAuthenticationInResponse={checkAuthenticationInResponse}
                APIEndpointDomain={APIEndpointDomain}
              />
            </div>
          )}
        />
        <Route
          path='/login'
          element={hideShowLogin(
            <LoginScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='/marketing'
          element={requireAuth(
            <MarketingScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='/'
          element={requireAuth(
            <HomePage
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />
        <Route
          path='performance/custom-report/:id'
          element={requireAuth(
            <PerformanceCustomReportScreen
              APIEndpointDomain={APIEndpointDomain}
            />
          )}
        />

        <Route
          path='lead-frequency'
          element={
            <LeadFrequencyScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />

        <Route
          path='calendar/slots-open'
          element={
            <SlotsOpenScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />

        <Route
          path='performance/ga-indirect'
          element={
            <PerformanceGAIndirectScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route
          path='performance/meta-indirect'
          element={
            <PerformanceMetaIndirectScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />

        <Route
          path='sessions/user-search'
          element={
            <UserSessionSearchScreen
              checkAuthenticationInResponse={checkAuthenticationInResponse}
              // style={{ backgroundColor: '#e8ebe9' }}
              APIEndpointDomain={APIEndpointDomain}
            />
          }
        />
        <Route path='*' element={<FourOhFour></FourOhFour>} />
      </Routes>
    </Router>
  )
}

export default App
