import axios from 'axios'
import { cn } from '../utils/cn.js'
import { motion } from 'framer-motion'
import { MovingBorderButton } from '../components/ui/moving-border-button.js'
import React from 'react'

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTrigger
} from '../components/ui/drawer.js'

function HomePage ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  function getUsername () {
    axios
      .get(APIEndpointDomain + '/username', {
        params: {},
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
        checkAuthenticationInResponse(Number(error.response.status))
      })
  }

  function logOut () {
    localStorage.clear()
    window.location.href = '/login'
  }

  getUsername()

  return (
    <>
      {/* <Boxes /> */}

      <LampContainer style={{ height: '100%', width: '100%' }}>
        <motion.h1
          initial={{ opacity: 0.5, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: 'easeInOut'
          }}
          className='mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight'
        >
          <div className='relative bg-slate-900 flex flex-col items-center justify-center rounded-lg bg-opacity-0	'>
            <div className='bg-opacity-0 absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none' />
            <h1 className={cn('md:text-4xl text-xl text-white relative z-20')}>
              {/* <TypewriterEffect
                words={[{ text: 'Marketing' }, { text: 'Tools' }]}
              /> */}
              Marketing Tools
            </h1>
            <h1 className={cn('md:text-2xl text-xl text-white relative z-20')}>
              Choose one
            </h1>
            <div
              className={cn('relative z-20 mt-2')}
              style={{ lineHeight: '15px' }}
            >
              <Drawer>
                <DrawerTrigger asChild>
                  {/* <div></div> */}

                  <MovingBorderButton
                    borderRadius='1.75rem'
                    className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                  >
                    Performance
                  </MovingBorderButton>
                </DrawerTrigger>
                <DrawerContent className='bg-slate-300'>
                  <div className='mx-auto w-full px-20'>
                    <DrawerHeader>
                      <div
                        className='content-center'
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <h4 className='text-3xl text-slate-800 font-bold mx-auto'>
                          Performance Tools
                        </h4>
                      </div>
                    </DrawerHeader>

                    <div className=''>
                      <div class='flex items-center justify-center flex-row '>
                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() =>
                            window.open('/performance/meta-direct')
                          }
                        >
                          Meta (Direct)
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() =>
                            window.open('/performance/meta-indirect')
                          }
                        >
                          Meta (Indirect)
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/performance/ga-direct')}
                        >
                          GA (Direct)
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() =>
                            window.open('/performance/ga-indirect')
                          }
                        >
                          GA (Indirect)
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/comment-management')}
                        >
                          Comments
                        </MovingBorderButton>
                      </div>
                    </div>

                    <div className='mx-auto w-full max-w-sm '>
                      <DrawerFooter>
                        <DrawerClose asChild></DrawerClose>
                      </DrawerFooter>
                    </div>
                  </div>
                </DrawerContent>
              </Drawer>

              <Drawer>
                <DrawerTrigger asChild>
                  {/* <div></div> */}

                  <MovingBorderButton
                    borderRadius='1.75rem'
                    className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                  >
                    Marketing
                  </MovingBorderButton>
                </DrawerTrigger>
                <DrawerContent className='bg-slate-300'>
                  <div className='mx-auto w-full px-20'>
                    <DrawerHeader>
                      <div
                        className='content-center'
                        style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <h4 className='text-3xl text-slate-800 font-bold mx-auto'>
                          Marketing Tools
                        </h4>
                      </div>
                    </DrawerHeader>

                    <div className=''>
                      <div class='flex items-center justify-center flex-row '>
                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/marketing')}
                        >
                          UTM-based Analytics
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/sessions/user-search')}
                        >
                          Lead Journey
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/lead-frequency')}
                        >
                          Lead Frequency
                        </MovingBorderButton>

                        <MovingBorderButton
                          borderRadius='1.75rem'
                          className='mr-1 rounded-md  text-sm transition bg-slate-800 dark:bg-slate-900 text-white dark:text-white border-neutral-200 dark:border-slate-800'
                          onClick={() => window.open('/calendar/slots-open')}
                        >
                          Slots Open
                        </MovingBorderButton>
                      </div>
                    </div>

                    <div className='mx-auto w-full max-w-sm '>
                      <DrawerFooter>
                        <DrawerClose asChild></DrawerClose>
                      </DrawerFooter>
                    </div>
                  </div>
                </DrawerContent>
              </Drawer>

              <br></br>

              <br></br>
              <MovingBorderButton
                borderRadius='1.75rem'
                className='mr-1 rounded-md  text-sm transition bg-white dark:bg-slate-900 text-dark dark:text-white border-neutral-200 dark:border-slate-800'
                onClick={() => logOut()}
              >
                Log Out{'  '}
              </MovingBorderButton>
            </div>{' '}
          </div>
        </motion.h1>
      </LampContainer>
    </>
  )
}

export default HomePage

const LampContainer = ({ children, className }) => {
  return (
    <>
      <div
        className={cn(
          'relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-slate-950 rounded-md z-0',
          'w-screen', // Add this line to make it fit the full screen width
          className
        )}
      >
        <div className='relative flex w-full flex-1 scale-y-125 items-center justify-center isolate z-0 '>
          <motion.div
            initial={{ opacity: 0.5, width: '15rem' }}
            whileInView={{ opacity: 1, width: '30rem' }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: 'easeInOut'
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`
            }}
            className='absolute inset-auto right-1/2 h-56 overflow-visible w-[30rem] bg-gradient-conic from-cyan-500 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]'
          >
            <div className='absolute  w-[100%] left-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]' />
            <div className='absolute  w-40 h-[100%] left-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_right,white,transparent)]' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0.5, width: '15rem' }}
            whileInView={{ opacity: 1, width: '30rem' }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: 'easeInOut'
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`
            }}
            className='absolute inset-auto left-1/2 h-56 w-[30rem] bg-gradient-conic from-transparent via-transparent to-cyan-500 text-white [--conic-position:from_290deg_at_center_top]'
          >
            <div className='absolute  w-40 h-[100%] right-0 bg-slate-950  bottom-0 z-20 [mask-image:linear-gradient(to_left,white,transparent)]' />
            <div className='absolute  w-[100%] right-0 bg-slate-950 h-40 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]' />
          </motion.div>
          <div className='absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-slate-950 blur-2xl'></div>
          <div className='absolute top-1/2 z-50 h-48 w-full bg-transparent opacity-10 backdrop-blur-md'></div>
          <div className='absolute inset-auto z-50 h-36 w-[28rem] -translate-y-1/2 rounded-full bg-cyan-500 opacity-50 blur-3xl'></div>
          <motion.div
            initial={{ width: '8rem' }}
            whileInView={{ width: '16rem' }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: 'easeInOut'
            }}
            className='absolute inset-auto z-30 h-36 w-64 -translate-y-[6rem] rounded-full bg-cyan-400 blur-2xl'
          ></motion.div>
          <motion.div
            initial={{ width: '15rem' }}
            whileInView={{ width: '30rem' }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: 'easeInOut'
            }}
            className='absolute inset-auto z-50 h-0.5 w-[30rem] -translate-y-[7rem] bg-cyan-400 '
          ></motion.div>

          <div className='absolute inset-auto z-40 h-44 w-full -translate-y-[12.5rem] bg-slate-950 '></div>
        </div>

        <div className='relative z-50 flex -translate-y-80 flex-col items-center px-5'>
          {children}
        </div>
      </div>
    </>
  )
}
