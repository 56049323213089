import MarketingTable from '../components/MarketingTable'
import MarketingHeader from '../components/MarketingHeader'
import { useState } from 'react'
import axios from 'axios'
import '../css/MarketingScreen.css'
import { useEffect, useRef } from 'react'

import {
  Widget,
  addResponseMessage,
  toggleWidget,
  addUserMessage,
  deleteMessages
} from 'react-chat-widget'
import '../css/GlobalChatbox.css'
import 'react-chat-widget/lib/styles.css'

function MarketingScreen ({ checkAuthenticationInResponse, APIEndpointDomain }) {
  var end = new Date().toJSON().slice(0, 10)
  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate() - 7))
    .toJSON()
    .slice(0, 10)

  var [showOnlyCreatedBetween, setShowOnlyCreatedBetween] = useState(true)
  var [selectedLevel, setSelectedLevel] = useState('General Report')
  var [DataIsLeadType, setDataIsLeadType] = useState('premium')
  var [isCBMetricSeleted, setisCBMetricSelected] = useState(false)
  var [sourceSearch, setSourceSearch] = useState('')
  var [mediumSearch, setMediumSearch] = useState('')
  var [campaignSearch, setCampaignSearch] = useState('')
  var [idSearch, setIdSearch] = useState('')
  var [termSearch, setTermSearch] = useState('')
  var [contentSearch, setContentSearch] = useState('')
  var [selectedGrouping, setSelectedGrouping] = useState({
    source: true,
    medium: false,
    campaign: false,
    id: false,
    term: false,
    content: false
  })
  var [favoritesData, setFavoritesData] = useState([])
  var [isError, setIsError] = useState(false)

  var [tableData, setTableData] = useState([])
  var [Data, setData] = useState(tableData)
  var [allViews, setAllViews] = useState([{ name: '' }])

  var [loadingP, setLoadingP] = useState(4)
  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(end)
  var [selectedDomain, setSelectedDomain] = useState('MiM-Essay')
  var [selectedLeadType, setSelectedLeadType] = useState('Premium')
  var [selectedSourceType, setSelectedSourceType] = useState('Combination')

  var [goTriggerPrimaryAPI, setGoTriggerPrimaryAPI] = useState(false)

  var [PrimaryRangeProcessed, setPrimaryRangeProcessed] = useState(false)

  var [CompareRangeProcessed, setCompareRangeProcessed] = useState(false)

  var [selectedRows, setSelectedRows] = useState([])

  var [selectedColumnFilters, setSelectedColumnFilters] = useState([
    'name',
    'spend',
    'revenue_during',
    'roi_during',

    'leads',
    'qualified',

    'quality',
    'h_good',
    'h_good_p',
    'h_ultra',
    'acquired_during',
    'cpl',
    'cpql',
    'h_cpgl',
    'cpa_during',
    'pending_p'
  ])

  var defaultAdvancedFilters = {
    minRevenue: 0,
    minLeads: 0,
    minQualified: 0,
    minGood: 0,
    minClients: 0,
    minQuality: 0,

    minAcquisitionDuring: 0,
    minRevenueDuring: 0,

    maxRevenue: 1000000000,
    maxLeads: 1000000000,
    maxQualified: 1000000000,
    maxGood: 1000000000,
    maxClients: 1000000000,
    maxQuality: 1000000000,

    maxRevenueDuring: 1000000000,
    maxAcquisitionDuring: 1000000000
  }

  var [advancedFiltersSelections, setAdvancedFilterSelections] = useState(
    defaultAdvancedFilters
  )

  var [advancedFilterModalShow, setAdvancedFilterModalShow] = useState(false)

  var [comparisonTableData, setComparisonTableData] = useState([])
  var [compareRangeRequirement, setCompareRangeRequirement] = useState(false)
  var [customReportNames, setCustomReportNames] = useState([''])
  var [selectedCustomReport, setSelectedCustomReport] = useState('')

  // useEffect(() => {
  //   getPrimaryTD(
  //     startDate,
  //     endDate,
  //     selectedLeadType,
  //     selectedSourceType,
  //     selectedColumnFilters,
  //     selectedDomain,
  //     'primary'
  //   )
  // }, [isCBMetricSeleted])

  useEffect(() => {
    setCompareRangeRequirement(false)
  }, [
    // startDate,
    // endDate,
    selectedSourceType,
    selectedLeadType,
    selectedDomain
  ])

  let cancelTokenSource = null

  var [tableDataAPICalling, setTableDataAPICalling] = useState(false)

  function getPrimaryTD (
    start_date,
    end_date,
    selected_lead_type,
    selected_source_type,
    selected_column_filters,
    selected_domain,
    range_type
  ) {
    setIsError(false)
    setTableDataAPICalling(true)
    range_type === 'primary'
      ? setPrimaryRangeProcessed(false)
      : setCompareRangeProcessed(false)
    setLoadingP(40)

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by the user.')
    }

    cancelTokenSource = axios.CancelToken.source()

    var containsCBMetrics = selected_column_filters.filter(
      filter =>
        filter.toString().toLowerCase().includes('_cb') ||
        filter.toString().toLowerCase().includes('call_booked')
    )

    var cb_metrics_needed = containsCBMetrics.length > 0 ? true : false

    axios
      .get(APIEndpointDomain + '/marketing/table-data', {
        params: {
          start_date: start_date,
          end_date: end_date,
          lead_type:
            selectedLeadType == 'Jobs'
              ? 'cpa'
              : selectedLeadType == 'GMAT'
              ? 'premium'
              : selectedLeadType.toLowerCase().replace('-', '_'),

          domain: selected_domain.toLowerCase().replace('-', '_'),
          source_type: selected_source_type.toLocaleLowerCase(),
          created_between: false,
          cb_metrics_needed: cb_metrics_needed,
          is_job: selectedLeadType == 'Jobs',
          is_gmat: selectedLeadType == 'GMAT'
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
        cancelToken: cancelTokenSource.token
      })
      .then(response => {
        setIsError(false)
        if (range_type === 'primary') {
          console.log(response)
          setLoadingP(60)

          const responseData = response.data['data']
          const views = response.data['views']

          setTableDataAPICalling(true)
          setLoadingP(70)
          setTableData(responseData)
          setAllViews(views)
          const favorites = response.data['favorite']
          setFavoritesData(favorites)
          setTableDataAPICalling(false)
          setCustomReportNames(response.data['custom_report_names'])
          setDataIsLeadType(response.data['lead_type'])
        } else {
          console.log(response)
          setTableDataAPICalling(true)

          setComparisonTableData(response.data['data'])
          setLoadingP(70)

          setTableDataAPICalling(false)
        }
      })
      .catch(error => {
        setIsError(true)
        setLoadingP(100)
        setTableDataAPICalling(true)
        setTableData([])
        setAllViews([])
        setFavoritesData([])
        setTableDataAPICalling(false)
        setCustomReportNames([])
        setTableDataAPICalling(false)
        // Check if the error is due to a canceled request
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message)
        } else {
          checkAuthenticationInResponse(Number(error.response.status))
          console.log(error)
        }
      })

    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled by the user.')
      }
    }
  }

  var preColumns = [
    'spend',
    'roi',
    'roi_during',
    'cpl',
    'cpql',
    'cpgl',
    'cpa',
    'h_cpa',
    'cpaa',
    'h_cpgl',
    'cpgl_during',
    'cpa_during',
    'name',
    'valid_calls_cb_p',
    'assigned_calls_cb_p',
    'calls_left_cb_p',
    'dnp_cb_p',
    'deleted_cb_p',
    'good_leads_cb_p',
    'acquisitions',
    'acquired_during',
    'valid_calls_cb',
    'assigned_calls_cb',
    'calls_left_cb',
    'good_leads_cb',
    'avg_purchase',
    'avg_conversion_period',
    'calls_booked_during',
    'deleted',
    'deleted_cb',
    'dnp_cb',
    'dnp_recovered',
    'good_leads',
    'good_leads_during',
    'good_percentage',
    'h_good',
    'h_good_p',
    'h_pressure',
    'h_ultra',
    'leads',
    'pending',
    'pending_p',
    'pressure_sell',
    'pressure_sell_during',
    'pressure_to_acquisition',
    'projected_acquisitions',
    'qualified',
    'quality',
    'revenue',
    'revenue_during',
    'ultra_hot',
    'ultra_hot_during',
    'uhl_to_acquisition',
    'upfront_payments',
    'upfront_payments_during'
  ].sort()

  var allColumnFilters =
    selectedLeadType.toLowerCase() !== 'cpa'
      ? preColumns
      : [
          ...preColumns,
          'admit_up',
          'admit_up_during',
          'admit_up_rev',
          'admit_up_rev_dur',
          'actually_acquired_during',
          'h_acquisitions',
          'actual_acquisitions',
          'premium_ms_revenue',
          'premium_ms_revenue_during',

          'acquisitions_lost',
          'acquisition_loss_rate',
          'acquisition_validation_rate',
          'acquisitions_pending_validation',
          'acquisitions_pending_validation_p',
          'avg_validation_period',
          'avg_complete_cycle_period',

          'cpa_acquisitions',
          'premium_acquisitions',
          'h_cpa_acquisitions',
          'h_premium_acquisitions',
          'premium_acquired_during',
          'cpa_acquired_during',
          'real_revenue_during',
          'real_acquisitions_during',
          'real_roi_during',
          'cpra_during'
        ].sort()

  return (
    <>
      <div className='h-[50rem] h-screen w-screen w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center'>
        <div className='absolute h-screen w-screen pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]'></div>
        <div className='grid grid-rows-2 gap-0 bg-opacity-0 h-screen w-screen'>
          <MarketingHeader
            loadingP={loadingP}
            goTriggerPrimaryAPI={goTriggerPrimaryAPI}
            setGoTriggerPrimaryAPI={setGoTriggerPrimaryAPI}
            showOnlyCreatedBetween={showOnlyCreatedBetween}
            setShowOnlyCreatedBetween={setShowOnlyCreatedBetween}
            selectedGrouping={selectedGrouping}
            setSelectedGrouping={setSelectedGrouping}
            columnFilters={allColumnFilters}
            setSourceSearch={setSourceSearch}
            setMediumSearch={setMediumSearch}
            setCampaignSearch={setCampaignSearch}
            setIdSearch={setIdSearch}
            setTermSearch={setTermSearch}
            setContentSearch={setContentSearch}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            defaultStartDate={start}
            defaultEndDate={end}
            setNewAdvanceFilter={setAdvancedFilterSelections}
            advancedFiltersSelections={advancedFiltersSelections}
            selectedColumnFilters={selectedColumnFilters}
            setSelectedColumnFilters={setSelectedColumnFilters}
            setSelectedDomain={setSelectedDomain}
            setSelectedLeadType={setSelectedLeadType}
            selectedLeadType={selectedLeadType}
            selectedDomain={selectedDomain}
            defaultAdvancedFilters={defaultAdvancedFilters}
            setAdvancedFilterModalShow={setAdvancedFilterModalShow}
            advancedFilterModalShow={advancedFilterModalShow}
            setSelectedSourceType={setSelectedSourceType}
            selectedSourceType={selectedSourceType}
            allViews={allViews}
            setAllViews={setAllViews}
            APIEndpointDomain={APIEndpointDomain}
            Data={Data}
            setData={setData}
            setisCBMetricSelected={setisCBMetricSelected}
            isCBMetricSeleted={isCBMetricSeleted}
            getPrimaryTD={getPrimaryTD}
            startDate={startDate}
            endDate={endDate}
            setComparisonTableData={setComparisonTableData}
            compareRangeRequirement={compareRangeRequirement}
            setCompareRangeRequirement={setCompareRangeRequirement}
            tableDataAPICalling={tableDataAPICalling}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
            favoritesData={favoritesData}
            setFavoritesData={setFavoritesData}
            customReportNames={customReportNames}
            setCustomReportNames={setCustomReportNames}
            selectedCustomReport={selectedCustomReport}
            setSelectedCustomReport={setSelectedCustomReport}
            sourceSearch={sourceSearch}
            mediumSearch={mediumSearch}
            campaignSearch={campaignSearch}
            idSearch={idSearch}
            termSearch={termSearch}
            contentSearch={contentSearch}
          />

          <MarketingTable
            selectedGrouping={selectedGrouping}
            sourceSearch={sourceSearch}
            mediumSearch={mediumSearch}
            campaignSearch={campaignSearch}
            idSearch={idSearch}
            termSearch={termSearch}
            contentSearch={contentSearch}
            tableData={tableData}
            selectedSourceType={selectedSourceType}
            setSelectedSourceType={setSelectedSourceType}
            loadingP={loadingP}
            setLoadingP={setLoadingP}
            advancedFilterSelections={advancedFiltersSelections}
            selectedColumnFilters={selectedColumnFilters}
            advancedFilterModalShow={advancedFilterModalShow}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            startDate={startDate}
            endDate={endDate}
            selectedLeadType={selectedLeadType}
            selectedDomain={selectedDomain}
            checkAuthenticationInResponse={checkAuthenticationInResponse}
            APIEndpointDomain={APIEndpointDomain}
            Data={Data}
            setData={setData}
            showOnlyCreatedBetween={showOnlyCreatedBetween}
            compareRangeRequirement={compareRangeRequirement}
            setCompareRangeRequirement={setCompareRangeRequirement}
            comparisonTableData={comparisonTableData}
            PrimaryRangeProcessed={PrimaryRangeProcessed}
            setPrimaryRangeProcessed={setPrimaryRangeProcessed}
            CompareRangeProcessed={CompareRangeProcessed}
            setCompareRangeProcessed={setCompareRangeProcessed}
            tableDataAPICalling={tableDataAPICalling}
            selectedLevel={selectedLevel}
            setSelectedLevel={setSelectedLevel}
            favoritesData={favoritesData}
            setFavoritesData={setFavoritesData}
            DataIsLeadType={DataIsLeadType}
            isError={isError}
          />
        </div>
      </div>
    </>
  )
}

export default MarketingScreen
