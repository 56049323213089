import Modal from 'react-bootstrap/Modal'

function APIRequestStatusModal ({ show, setShow, message }) {
  return (
    <div>
      <Modal size='sm' show={show}>
        <Modal.Header
          onClick={() => {
            setShow(false)
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
            backgroundColor: '#525252',
            color: 'white'
          }}
          closeButton
        >
          <Modal.Title>{'Status'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
      </Modal>
    </div>
  )
}

export default APIRequestStatusModal
