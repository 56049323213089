import CommentManagementHeader from '../components/CommentManagementHeader'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import CommentManagementTable from '../components/CommentManagementTable'
import * as React from 'react'

function CommentManagementScreen ({
  APIEndpointDomain,
  checkAuthenticationInResponse
}) {
  var [startDate, setStartDate] = useState(start)
  var [endDate, setEndDate] = useState(end)
  var end = new Date().toJSON().slice(0, 10)
  var today = new Date()
  var start = new Date(new Date().setDate(today.getDate() - 7))
    .toJSON()
    .slice(0, 10)
  var [selectedAdAccount, setSelectedAdAccount] = useState('MentR-Me')
  var [tableData, setTableData] = useState([])
  var [APICalling, setAPICalling] = useState(true)
  var [APIError, setAPIError] = useState(false)

  var [selectedCommentIDs, setSelectedCommentIDs] = useState([])

  let cancelTokenSource = null

  function onAdAccountSelect (ad_account) {
    setSelectedAdAccount(ad_account)
    setAPICalling(true)
    setTableData([])
    setAPIError(false)

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled by the user.')
    }

    // Create a new cancel token
    cancelTokenSource = axios.CancelToken.source()
    axios
      .get(APIEndpointDomain + '/comments/all', {
        params: {
          start_date: startDate,
          end_date: endDate,
          ad_account: ad_account
        },
        headers: {
          'ngrok-skip-browser-warning': 'any',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },
        cancelToken: cancelTokenSource.token
      })
      .then(response => {
        setTableData([])
        setAPIError(false)

        const responseData = response.data['result']
        setTableData(responseData)
        console.log(response)
        setAPICalling(false)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message)
        } else {
          checkAuthenticationInResponse(Number(error.response.status))
          setAPIError(true)
          setAPICalling(false)
          console.log(error)
        }
      })
  }

  useEffect(() => {
    let ignore = false

    if (!ignore) onAdAccountSelect(selectedAdAccount)
    return () => {
      ignore = true
    }
  }, [])

  return (
    <>
      <div className='bg-slate-300'>
        <div className='fixed inset-0 z-0 overflow-x-hidden'>
          <div className='absolute inset-0 bg-gradient-to-b from-transparent to-white'></div>
        </div>
        <div className='relative z-10 overflow-x-hidden'>
          <div className='flex '>
            <div className='bg-white bg-opacity-50 w-full'>
              {/* Check for border and shadow */}
              <CommentManagementHeader
                onAdAccountSelect={onAdAccountSelect}
                setSelectedDomain={setSelectedAdAccount}
                selectedDomain={selectedAdAccount}
                APICalling={APICalling}
                selectedCommentIDs={selectedCommentIDs}
                setSelectedCommentIDs={setSelectedCommentIDs}
                APIEndpointDomain={APIEndpointDomain}
                selectedAdAccount={selectedAdAccount}
                setTableData={setTableData}
                tableData={tableData}
              ></CommentManagementHeader>
              <CommentManagementTable
                tableData={tableData}
                selectedAdAccount={selectedAdAccount}
                APIEndpointDomain={APIEndpointDomain}
                setTableData={setTableData}
                APICalling={APICalling}
                APIError={APIError}
                selectedCommentIDs={selectedCommentIDs}
                setSelectedCommentIDs={setSelectedCommentIDs}
              ></CommentManagementTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommentManagementScreen
